import React from 'react'
import { Modal, FloatingLabel, Form, Row, Col, Button } from 'react-bootstrap'
const ModalNuevoControlComponent = (props) => {

    async function GenerarNuevoControl(e){
        e.preventDefault()
        let getFecha = document.getElementById('New_Fecha_Control')
        let getDetalle = document.getElementById('New_Detalle')
        let getPRofesional = document.getElementById('New_Profesional')
        let NewControl = {
            Fecha : getFecha.value,
            Detalle : getDetalle.value,
            Profesional : getPRofesional.value
        }
        props.NuevoControl(NewControl)
    }
    async function CloseModal(){
        props.CloseModal()
    }

    return (
        <Modal show={props.isOpen} size="lg" onHide={(e) => CloseModal(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva Atenci&oacute;n</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <FloatingLabel
                            label="Fecha Control"
                            className="mb-3"
                        >
                            <Form.Control required={true} type="date" id="New_Fecha_Control" />
                        </FloatingLabel>

                    </Row>
                    <Row>
                        <FloatingLabel
                            label="Detalle"
                            className="mb-3"
                        >
                            <Form.Control required={true} type="text" id="New_Detalle" />
                        </FloatingLabel>
                    </Row>
                    <Row>
                        <FloatingLabel
                            label="Fecha Control"
                            className="mb-3"
                        >
                            <Form.Control required={true} type="text" id="New_Profesional" />
                        </FloatingLabel>

                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => CloseModal(e)}>
                        Cerrar
                    </Button>
                    <Button type="button"  variant="primary" onClick={(e)=> GenerarNuevoControl(e)}>
                        Nuevo Control
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}

export default ModalNuevoControlComponent
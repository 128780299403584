import React, { useState, useEffect, Fragment } from 'react';
import { Card, Tabs, Col, Button, Container, Tab, Row, FloatingLabel, Form } from 'react-bootstrap';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { ExclamationTriangleIcon, NewspaperIcon, XMarkIcon } from '@heroicons/react/24/outline'
import ModalNuevoControl from '../Components/ModalNuevoControl'
import './Detail.css'
const DetailPage = () => {

    let location = useLocation();
    const [DatosFicha, setDatosFicha] = useState([])
    const [Controles, setControles] = useState([])
    const FichaDefault = location.state.Ficha
    let { Tipo, id } = useParams();
    useEffect(() => {
        LoadData()
        console.log(FichaDefault)
        setDatosFicha()
    }, [])

    async function LoadData() {
        setControles(FichaDefault.DatosControles)
        let getFecha = document.getElementById('Mod_DA_Fecha')
        getFecha.value = FichaDefault.DatosAtencion.Fecha
        let fecha_Generada = getFecha.value
        let get_DA_Detalle = document.getElementById('Mod_DA_Detalle')
        get_DA_Detalle.value = FichaDefault.DatosAtencion.DetalleAtencion
        let get_DA_Marca = document.getElementById('Mod_DA_MarcaLC')
        get_DA_Marca.value = FichaDefault.DatosAtencion.MarcaLC
        let get_DA_OD = document.getElementById('Mod_DA_OD')
        get_DA_OD.value = FichaDefault.DatosAtencion.OD
        let get_DA_AVODLejos = document.getElementById('Mod_DA_AVODLejos')
        get_DA_AVODLejos.value = FichaDefault.DatosAtencion.AVODLejos
        let get_DA_AVODCerca = document.getElementById('Mod_DA_AVODCerca')
        get_DA_AVODCerca.value = FichaDefault.DatosAtencion.AVODCerca
        let get_DA_CBOD = document.getElementById('Mod_DA_CurvaBaseOD')
        get_DA_CBOD.value = FichaDefault.DatosAtencion.CurvaBaseOD
        let get_DA_DiamOD = document.getElementById('Mod_DA_DiametroOD')
        get_DA_DiamOD.value = FichaDefault.DatosAtencion.DiametroOD
        let get_DA_OI = document.getElementById('Mod_DA_OI')
        get_DA_OI.value = FichaDefault.DatosAtencion.OI
        let get_DA_AVOILejos = document.getElementById('Mod_DA_AVOILejos')
        get_DA_AVOILejos.value = FichaDefault.DatosAtencion.AVOILejos
        let get_DA_AVOICerca = document.getElementById('Mod_DA_AVOICerca')
        get_DA_AVOICerca.value = FichaDefault.DatosAtencion.AVOICerca
        let get_DA_CBOI = document.getElementById('Mod_DA_CurvaBaseOI')
        get_DA_CBOI.value = FichaDefault.DatosAtencion.CurvaBaseOI
        let get_DA_DiamOI = document.getElementById('Mod_DA_DiametroOI')
        get_DA_DiamOI.value = FichaDefault.DatosAtencion.DiametroOI
        let get_DA_AVDetalle = document.getElementById('Mod_DA_AVDetalle')
        get_DA_AVDetalle.value = FichaDefault.DatosAtencion.AVDetalle
        let get_DA_Profesional = document.getElementById('Mod_DA_Profesional')
        get_DA_Profesional.value = FichaDefault.DatosAtencion.Profesional
        let get_DE_Fecha = document.getElementById('Mod_DE_Fecha')
        get_DE_Fecha.value = FichaDefault.DatosEntrega.Fecha
        let get_DE_Profesional = document.getElementById('Mod_DE_Profesional')
        get_DE_Profesional.value = FichaDefault.DatosEntrega.Profesional
        let get_DE_Detalle = document.getElementById('Mod_DE_Detalle')
        get_DE_Detalle.value = FichaDefault.DatosEntrega.Detalle
    }

    const [OpenModalNewControl, setOpenModalNewControl] = useState(false)
    async function NuevoControl() {
        setOpenModalNewControl(true)
    }
    const CerrarNuevoModal = () => setOpenModalNewControl(false)
    async function NewPushControl(newControl) {
        let cont = Controles
        cont.push(newControl)
        setControles(cont)
        setOpenModalNewControl(false)
    }
    return (
        <>
            <Tabs
                defaultActiveKey="DetalleAtencion"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="DetalleAtencion" title="Detalle Atencion">
                    <Row>
                        <FloatingLabel
                            label="Fecha Atencion"
                            className="mb-3"
                        >
                            <Form.Control type="date" id="Mod_DA_Fecha" />
                        </FloatingLabel>

                    </Row>
                    <Row>
                        <FloatingLabel
                            label={`Detalle`}
                            className="mb-3"
                        >
                            <Form.Control as="textarea" id="Mod_DA_Detalle" style={{ height: '300px' }} />
                        </FloatingLabel>

                    </Row>
                    <Row>
                        <FloatingLabel
                            label="Marca Lente de Contacto"
                            className="mb-3"
                        >
                            <Form.Control type="text" id="Mod_DA_MarcaLC" />
                        </FloatingLabel>

                    </Row>
                    <Row className="text-left">
                        <Form.Label htmlFor="inputPassword5">
                            OD
                        </Form.Label>

                    </Row>
                    <Container>
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="Curva Base"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_CurvaBaseOD" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="Potencia"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_OD" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="Diametro"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_DiametroOD" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="AV Lejos"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_AVODLejos" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="AV Cerca"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_AVODCerca" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                    <Row className="text-left">
                        <Form.Label htmlFor="inputPassword5">OI</Form.Label>

                    </Row>
                    <Container>
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="Curva Base"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_CurvaBaseOI" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="Potencia"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_OI" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="Diametro"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_DiametroOI" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="AV Lejos"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_AVOILejos" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FloatingLabel
                                    label="AV Cerca"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" id="Mod_DA_AVOICerca" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                    <Row>
                        <FloatingLabel
                            label={`Av Detalle`}
                            className="mb-3"
                        >
                            <Form.Control type='text' id="Mod_DA_AVDetalle" />
                        </FloatingLabel>

                    </Row>
                    <Row>
                        <FloatingLabel
                            label="Profesional"
                            className="mb-3"
                        >
                            <Form.Control type="text" id="Mod_DA_Profesional" />
                        </FloatingLabel>

                    </Row>
                    <br/>
                </Tab>
                <Tab eventKey="DetalleEntrega" title="Detalle Entrega">
                    <Row>
                        <FloatingLabel
                            label="Fecha Entrega"
                            className="mb-3"
                        >
                            <Form.Control type="date" id="Mod_DE_Fecha" />
                        </FloatingLabel>

                    </Row>
                    <Row>
                        <FloatingLabel
                            label={`Información`}
                            className="mb-3"
                        >
                            <Form.Control as="textarea" id="Mod_DE_Detalle" style={{ height: '300px' }} />
                        </FloatingLabel>

                    </Row>
                    <Row>
                        <FloatingLabel
                            label="Profesional"
                            className="mb-3"
                        >
                            <Form.Control type="text" id="Mod_DE_Profesional" />
                        </FloatingLabel>

                    </Row>
                </Tab>
                <Tab eventKey="Controles" title="Controles">
                    <nav aria-label="Breadcrumb">
                        <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                            <li>
                                <div className="flex items-center">
                                    <span className="sm:ml-3">
                                        <button
                                            type="button" onClick={() => NuevoControl()}
                                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <NewspaperIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Nuevo Control
                                        </button>
                                    </span>

                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    {Controles.map((item, index) =>
                        <Fragment key={index}>
                            <Card>
                                <Card.Header>
                                    <Card.Title className="grid justify-items-end">
                                            <Col >
                                                <button
                                                    type="button"
                                                    className="-mr-1 flex text-right rounded-md p-2 bg-red-800 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                                                >
                                                    <span className="sr-only">Dismiss</span>
                                                    <XMarkIcon className="text-right h-6 w-6 text-white" aria-hidden="true" />
                                                </button>
                                            </Col>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <FloatingLabel
                                            label="Fecha Control"
                                            className="mb-3"
                                        >
                                            <Form.Control size="sm" type="date" defaultValue={item['Fecha']} />
                                        </FloatingLabel>

                                    </Row>
                                    <Row>
                                        <FloatingLabel
                                            label="Detalle"
                                            className="mb-3"
                                        >
                                            <Form.Control size="sm" type="text" defaultValue={item['Detalle']} />
                                        </FloatingLabel>
                                    </Row>
                                    <Row>
                                        <FloatingLabel
                                            label="Fecha Control"
                                            className="mb-3"
                                        >
                                            <Form.Control size="sm" type="text" defaultValue={item['Profesional']} />
                                        </FloatingLabel>

                                    </Row>
                                </Card.Body>
                            </Card>
                        </Fragment>
                    )}
                    <ModalNuevoControl isOpen={OpenModalNewControl} NewControl={(item) => NewPushControl(item)} CloseModal={CerrarNuevoModal} />
                </Tab>

            </Tabs>
            <br/>
            <Button variant='danger' className='btn-flotante' type='button'>Actualizar</Button>
 
        </>
    )
}

export default DetailPage;
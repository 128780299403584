import React from 'react'
import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { useProSidebar } from 'react-pro-sidebar';
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import { useSelector, useDispatch } from 'react-redux'
import { Loggin } from '../../features/Account/AccountSlice'
const user = JSON.parse(localStorage.getItem('User'))

const navigation = [
    { name: 'Inventario', href: '/Admin/Bodega/Inventario', current: true },
    { name: 'Ingreso Mercaderia', href: '/Admin/Bodega/Inventario/Ingreso', current: false },
    { name: 'Salida Mercaderia', href: '/Admin/Bodega/Inventario/Salida', current: false },
    { name: 'Salida Mercaderia Por Venta', href: '/Admin/Bodega/Inventario/SalidaPorVenta', current: false },
    { name: 'Registro De Ingresos', href: '/Admin/Bodega/Inventario/Registro/Ingreso', current: false },
    { name: 'Registro De Salidas', href: '/Admin/Bodega/Inventario/Registro/Salidas', current: false },
]
const navigation2 = [
    { name: 'Inventario', href: '/Admin/Bodega/Iventario', current: true },
    { name: 'Dashboard', href: '/Admin/Dashboard', current: true },
    { name: 'Calendario', href: '/Admin/Calendario', current: false },
    { name: 'Clientes', href: '/Admin/Clientes', current: false },
    { name: 'Lentes de Contacto (antiguo)', href: '/Admin/LC', current: false },
    { name: 'Fichas', href: '/Admin/Fichas', current: false }
]
const userNavigation = [
    { name: 'Mi Perfil', href: '/Admin/Profile' },
    { name: 'Logout', href: '#' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const NavbarComponent = (props) => {
    const sortOptions = [
        { name: 'Most Popular', href: '#', current: true },
        { name: 'Best Rating', href: '#', current: false },
        { name: 'Newest', href: '#', current: false },
        { name: 'Price: Low to High', href: '#', current: false },
        { name: 'Price: High to Low', href: '#', current: false },
    ]
    async function ChangeState() {
        props.estadoNavbar(true)
    }


    return (
        <>
            <div className="flex items-baseline justify-between border-b border-gray-200 pt-2 pb-2">
                <img
                    className="h-8 w-8"
                    src="/assets/Icon.svg"
                    alt="OpticaReflejosChile"
                />
                <div className="flex items-center">
                    <button
                        type="button"
                        className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                        onClick={() => ChangeState()}
                    >
                        <span className="sr-only">Filters</span>
                        <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <Menu as="div" className="relative ml-3">
                        <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="sr-only">Open user menu</span>
                                <img className="h-8 w-8 rounded-full" src={`https://opticareflejos.ddns.net:5000/upload/${user.Img}`} alt="" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                    <span className="block px-4 py-2 text-sm text-gray-700">{useSelector(state => state.AccountState.userInfo.Nombre)}</span>
                                </Menu.Item>
                                <Menu.Item>
                                    <span className="block px-4 py-2 text-sm text-gray-700">{useSelector(state => state.AccountState.Role)}</span>
                                </Menu.Item>

                                {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <a
                                                href={item.href}
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-gray-700'
                                                )}
                                            >
                                                {item.name}
                                            </a>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>

        </>
    )
}

export default NavbarComponent

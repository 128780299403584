import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import './Process.css'
const Process = (props) => {
    
    async function CerrarModal(){
        props.CloseModal()
    }
    const cancelButtonRef = useRef(null)
    return (
        <>
            <Transition.Root show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>CerrarModal()}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center" style={{ background: "rgba(0, 0, 0, 0.3)" }}>
                                        <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
                                            <div className="loader-dots block relative w-20 h-5 mt-2">
                                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
                                            </div>
                                            <div className="text-gray-500 text-xs font-medium mt-2 text-center">
                                                Cargando...
                                            </div>
                                        </div>
                                    </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <>


                
            </>
        </>
    )
}

export default Process
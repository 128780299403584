import { createSlice } from '@reduxjs/toolkit'

export const AccountSlice = createSlice({
  name: 'Logged',
  initialState: {
    isLoggin: false,
    userToken: null,
    userInfo: {},
    Role : null
  },
  reducers: {
    Loggin : (state,action)=>{
      const { userToken, userInfo, Role } = action.payload;
        state.isLoggin=true
        state.userToken=userToken
        state.userInfo=userInfo
        state.Role=Role
    },
    Logout : (state)=>{
        state.isLoggin=false
        state.userToken=null
        state.userInfo=null
        state.Role=null
    },
    
    
  },
})

// Action creators are generated for each case reducer function
export const { Loggin, Logout} = AccountSlice.actions

export default AccountSlice.reducer
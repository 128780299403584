
import React, { Fragment, useRef, useState } from 'react'
import { Modal, Row,Button } from 'react-bootstrap'
const ModalComponent = (props) => {
    const CerrarModal = () => {
        props.CloseModal()
    }

    return (
        <Modal centered size="xl" className="bg-transparent" show={props.isOpen} onHide={CerrarModal} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title>Error Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>Ingreso No Valido</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={CerrarModal}>
                    Close
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default ModalComponent
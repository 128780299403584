import React from 'react';
import { BrowserRouter as Router,Route,Routes } from "react-router-dom"
import {GetAccoutGoogle} from '../function/Firebase'

//Home
import LayoutHome from '../View/Home/Layout/Layout';
import HomePage from '../View/Home/Home'

//Layout
import LayoutAdmin from '../View/Admin/Layout/Layout'

import LoginPage from  '../View/Login/LoginPage'
import RegisterPage from '../View/Register/RegisterPage'
import DashboardPage from '../View/Admin/Dashboard/Dashboard';


// Calendario
import Calendario from '../View/Admin/Calendario/Calendario'
// Clientes
import ClientesMain from '../View/Admin/Clientes/MainPage'
//Fichas
import FichasMain from '../View/Admin/Clientes/Fichas/MainPage'
import NuevaFichaPage from '../View/Admin/Clientes/Fichas/Nueva'
import DetalleFichaPage from '../View/Admin/Clientes/Fichas/Detail'
//Lentes De Contacto
import LentesContactoPage from '../View/Admin/Clientes/LentesContacto/MainPage'
import DetalleLentesContactoPage from '../View/Admin/Clientes/LentesContacto/Detail'
import NuevaFichaLentesContactoPage from '../View/Admin/Clientes/LentesContacto/Nueva'

// Bodega
// Cristales
import BodegaCristalesMainPage from '../View/Admin/Bodegas/Cristales/mainPage'
// Excel Files
import ExcelFilesMainPage from '../View/Admin/Excel/ExcelPage'
// Inventario

import InventarioMain from '../View/Admin/Bodegas/Armazones/MainPage'
// Ingreso Mercaderia
import InventarioArmazonesSalidaPorVenta from '../View/Admin/Bodegas/Armazones/SalidaPorVenta'
import InventarioArmazonesIngresoMercaderia from '../View/Admin/Bodegas/Armazones/IngresoMercaderia'
import InventarioArmazonesSalidaMercaderia from '../View/Admin/Bodegas/Armazones/SalidaMercaderia'
import InventarioArmazonesSalidaMermasGarantia from '../View/Admin/Bodegas/Armazones/MermasGarantias'
import InventarioArmazonesRegistroSalidas from '../View/Admin/Bodegas/Armazones/RegistroSalida'
import InventarioArmazonesRegistroIngreso from '../View/Admin/Bodegas/Armazones/RegistroIngreso'
import InventarioArmazonesRegistroSalidasVentas from '../View/Admin/Bodegas/Armazones/RegistroSalidaPorVenta'
import InventarioArmazonesRegistroMermasGarantias from '../View/Admin/Bodegas/Armazones/RegistroMermasGarantias'
import Process from '../View/component/Proccess';

const MainRouter = () =>{

     
    return(
        <Router>
            <Routes>
                <Route path='/' element={<LoginPage />}>
                    {/*}<Route index element={<LoginPage/>}/>{*/}
                </Route>
                <Route path='/Process' element={<Process/>}/>
                <Route path='Admin' element={<LayoutAdmin />}>
                    <Route index element={<DashboardPage/>}/>
                    <Route path='Dashboard' element={<DashboardPage />}/>
                    <Route path='Calendario' element={<Calendario/>}/>
                    <Route path='Clientes'>
                        <Route index element={<ClientesMain/>}/>
                        <Route path='Fichas'>
                            <Route index element={<FichasMain />}/>
                            <Route path='Nueva' element={<NuevaFichaPage/>} />
                            <Route path='Detalle' element={<DetalleFichaPage/>} />
                        </Route>
                        <Route path='LentesContacto'>
                            <Route index ={<LentesContactoPage />} />
                            <Route path=':Tipo'>
                                <Route index element={<LentesContactoPage/>}/>
                                <Route path='Detalle' element={<DetalleLentesContactoPage />}/>
                                <Route path='Nueva' element={<NuevaFichaLentesContactoPage />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path='Excel'>
                        <Route index element={<ExcelFilesMainPage />} />
                    </Route>
                    <Route path='Bodega'>
                        <Route path='Inventario'>
                            <Route index element={<InventarioMain/>}/>
                            <Route path='Ingreso'>
                                <Route index element={<InventarioArmazonesIngresoMercaderia />} />
                            </Route>
                            <Route path='Salida'>
                                <Route index element={<InventarioArmazonesSalidaMercaderia />} />
                            </Route>
                            <Route path='SalidaPorVenta'>
                                <Route index element={<InventarioArmazonesSalidaPorVenta />}/>
                            </Route>
                            <Route path='MermasGarantias'>
                                <Route index element={<InventarioArmazonesSalidaMermasGarantia/>} />
                            </Route>
                            <Route path='Registro'>
                                <Route path='Salidas'>
                                    <Route index element={<InventarioArmazonesRegistroSalidas/>} />
                                </Route>
                                <Route path='Ingreso'>
                                    <Route index element={<InventarioArmazonesRegistroIngreso/>}/>
                                </Route>
                                <Route path='SalidasPorVentas'>
                                    <Route index element={<InventarioArmazonesRegistroSalidasVentas/>} />
                                </Route>
                                <Route path='MermasGarantias'>
                                    <Route index element={<InventarioArmazonesRegistroMermasGarantias/>} />
                                </Route>
                            </Route>
                            
                        </Route>
                        <Route path='Cristales'>
                            <Route index element={<BodegaCristalesMainPage />} />
                        </Route>
                    </Route>
                </Route>
                <Route path='/Login' element={<LoginPage />} />
                <Route path='/Register' element={<RegisterPage />} />
                
                <Route path="*" element={'Error Page'} />
            </Routes>
        </Router> 
    )
}

export default MainRouter
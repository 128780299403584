import React, { Fragment } from "react";
import {Link} from 'react-router-dom'
const MiniSidebar = () => {
    const navigation = [
        { name: 'Inventario', href: '/Admin/Bodega/Inventario', current: true },
        { name: 'Ingreso Mercaderia', href: '/Admin/Bodega/Inventario/Ingreso', current: false },
        { name: 'Salida Mercaderia', href: '/Admin/Bodega/Inventario/Salida', current: false },
        { name: 'Salida Mercaderia Por Venta', href: '/Admin/Bodega/Inventario/SalidaPorVenta', current: false },
        { name: 'Registro De Ingresos', href: '/Admin/Bodega/Inventario/Registro/Ingreso', current: false },
        { name: 'Registro De Salidas', href: '/Admin/Bodega/Inventario/Registro/Salidas', current: false },
    ]
    return (
        <>
            <ul role={'list'} className="px-2 py-3 font-medium text-gray-900">
                {navigation.map((items, index) =>
                    <Fragment key={index}>
                        <li >
                            <Link to={items.href} className="block px-2 py-3 no-underline hover:bg-gray-50">
                                {items.name}
                            </Link>

                        </li>
                    </Fragment>
                )}

            </ul>


        </>
    )
}

export default MiniSidebar
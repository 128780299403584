import React, { useState, useEffect, Fragment } from 'react'
import { GenerarRegistroIngresoProveedor, GetDBProductos, PushProductsBodega } from '../../../../function/functions'
import Autosuggest from 'react-autosuggest'
import { Button, Form, Table } from 'react-bootstrap'
import './Style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import ModalNuevoProductoDB from './Components/ModalNuevoProductoDB'
import Process from '../../../component/Proccess'

const IngresoMercaderiaPage = () => {
  const user = JSON.parse(localStorage.getItem('User'))
  const [OpenModalNuevoProd, setOpenModalNuevoProd] = useState(false)
  const [ShowProgress, setShowProgress] = useState(false)
  const [DbProductos, setDbProductos] = useState([])
  const [ListaProductos, setListaProductos] = useState([])
  const [ProductoSeleccionado, setProductoSeleccionado] = useState([])
  const [ListadoIngresoBodega, setListadoIngresoBodega] = useState([])
  const Navigate = useNavigate();

  const [value, setValue] = useState("");

  useEffect(() => {
    LoadData()
  }, [])
  async function LoadData() {

    let resDB = await GetDBProductos()
    setListaProductos(resDB.data)
    setDbProductos(resDB.data)
  }
  const onSuggestionsFetchRequested = async ({ value }) => {
    Filtrar(value)

  }
  async function Filtrar(e) {
    if (e.length >= 1) {
      let regex = new RegExp(escapeRegex(e), 'gi');
      let res = DbProductos.filter((items) => items.Nombre.match(regex) || items.Marca.match(regex))
      setListaProductos(res.slice(0, 10))
    } else {
      setListaProductos([])
    }


  }
  const escapeRegex = (string) => {
    return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };
  const onSuggestionsClearRequested = () => {
    setListaProductos([]);
  }
  const getSuggestionValue = (suggestion) => {
    return `${suggestion.Nombre}`;
  }
  const renderSuggestion = (suggestion) => (
    <div className='sugerencia' onClick={() => seleccionarPresidente(suggestion)}>
      {`${suggestion.Nombre}`}
    </div>
  );
  const seleccionarPresidente = (Producto) => {
    setProductoSeleccionado(Producto);
  }
  const onChange = (e, { newValue }) => {
    setValue(newValue);
  }
  const inputProps = {
    placeholder: "Ingrese Un Producto",
    value,
    id:'Buscador',
    required: true,
    className: 'w-full',
    onChange
  };
  const eventEnter = (e) => {
    if (e.key === "Enter") {
      var split = e.target.value.split('-');
      var Producto = {
        Nombre: split[0].trim(),
        Marca: split[1].trim(),
      };
      seleccionarPresidente(Producto);
    }
  }
  const Ingresar = async (e) => {
    e.preventDefault()
    let containerObject = []
    let CantidadIngreso = document.getElementById('Cantidad').value
    if (ProductoSeleccionado._id) {
      let newObject = {
        idProducto: ProductoSeleccionado._id,
        Producto: ProductoSeleccionado.Nombre,
        Cantidad: parseInt(CantidadIngreso)
      }
      containerObject = ListadoIngresoBodega.concat(newObject)
      setListadoIngresoBodega(containerObject)
      console.log(containerObject)
    } else {
      ErrorAutocomplete()
    }
    setProductoSeleccionado([])
    let textProduc = document.getElementById('Buscado')
    textProduc.value=''
    
  }
  async function EliminarItem(indice) {
    let Array = ListadoIngresoBodega.filter((item, index) => index !== indice)
    console.log(Array)
    setListadoIngresoBodega(Array)
  }
  async function PushProductos() {
    setShowProgress(true)
    let NFactura = document.getElementById('NFactura')
    let Proveedor = document.getElementById('Proveedor')
    let FechaEmision = document.getElementById('FechaEmision')
    let FechaRecepcion = document.getElementById('FechaRecepcion')
    let formData1 = new FormData()
    formData1.append('NFactura', NFactura.value)
    formData1.append('Proveedor', Proveedor.value)
    formData1.append('FechaEmision', FechaEmision.value)
    formData1.append('FechaRecepcion', FechaRecepcion.value)
    formData1.append('Productos', JSON.stringify(ListadoIngresoBodega))
    formData1.append('IngresadoPor', user.Nombre)
    let resp2 = await GenerarRegistroIngresoProveedor(formData1)
    console.log(resp2)
    let formData = new FormData()
    formData.append('Productos', JSON.stringify(ListadoIngresoBodega))
    setTimeout(() => {
      setShowProgress(false)
    }, 3000);
    let res = await PushProductsBodega(formData)
    for (let i = 0; i < res.data.length; i++) {
      res.data[i].event === 'success' && SuccessNotify(res.data[i])
      res.data[i].event === 'error' && ErrorNotify(res.data[i])
    }

    setListadoIngresoBodega([])
    NFactura.value = ''
    Proveedor.value = ''
    FechaEmision.value = ''
    FechaRecepcion.value = ''
    console.log(res.data)
  } 
  const ErrorAutocomplete = () => {
    toast.error('Seleccione un Producto Valido Del Autocompletado', {
      position: "top-left",
      closeOnClick: false,
      pauseOnFocusLoss: false,
      theme: "colored"
    });
  }
  const ErrorNotify = (event) => {
    toast.error(event.message, {
      position: "top-left",
      closeOnClick: false,
      pauseOnFocusLoss: false,
      theme: "colored"
    });
  }
  const SuccessNotify = (event) => {
    toast.success(event.message, {
      closeOnClick: false,
      pauseOnFocusLoss: false,
      theme: "colored"
    });
  }
  
  async function CerrarModalNuevoProd() {
    LoadData()
    setProductoSeleccionado([])
    setOpenModalNuevoProd(false)
  }

  const RedirectVerInventario = () => {
    Navigate('/Admin/Bodega/Inventario')
  }

  const RedirectSalidaMercaderia = () => {
    Navigate('/Admin/Bodega/Inventario/Salida')
  }
  const RedirectRegistroSalidaMercaderia =()=>{
    Navigate('/Admin/Bodega/Inventario/Registro/Salida')
  }
  return (
    <>
      
      <ToastContainer />
      
      <br />

      <Form onSubmit={(e) => Ingresar(e)}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <td colSpan={3}>
                Seleccione El Producto y Cantidad a Ingresar. Si el Producto No Existe Haga CLick Aqui => <Button type="button" onClick={() => setOpenModalNuevoProd(true)} size='sm' variant='secondary'>+</Button>
              </td>
            </tr>
            <tr>
              <td>Filtrar Producto</td>
              <td>Cantidad</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Autosuggest
                id="Buscador"
                suggestions={ListaProductos}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={eventEnter}
              /></td>
              <td><input type='number' min={1} defaultValue={1} id="Cantidad" className='w-full' required={true} /></td>
              <td><Button variant='primary' type="submit">Seleccionar</Button></td>
            </tr>
          </tbody>
        </Table>
        
        <br />
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <td>N° Factura</td>
              <td>Proveedor</td>
              <td>Fecha Emision</td>
              <td>Fecha Recepcion</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form.Control type="text" id="NFactura" />
              </td>
              <td>
                <Form.Control type="text" id="Proveedor" />
              </td>
              <td>
                <Form.Control type="date" id="FechaEmision" />
              </td>
              <td>
                <Form.Control type="date" id="FechaRecepcion" />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <td>Producto</td>
              <td>Cantidad</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {ListadoIngresoBodega.map((item, index) =>
              <tr key={index}>
                <td>{item.Producto}</td>
                <td>{item.Cantidad}</td>
                <td><Button type="button" variant='danger' size='sm' onClick={() => EliminarItem(index)}>Eliminar</Button></td>
              </tr>
            )}
          </tbody>
          {ListadoIngresoBodega.length >= 1 &&
            <Fragment>
              <tfoot>
                <tr>
                  <td colSpan='3'>
                    <Button variant='primary' onClick={() => PushProductos()} size='sm' type="button">Ingresar</Button>
                  </td>
                </tr>
              </tfoot>
            </Fragment>
          }
        </Table>













      </Form>
      <ModalNuevoProductoDB isOpen={OpenModalNuevoProd} CloseModal={() => CerrarModalNuevoProd()} />
      <Process isOpen={ShowProgress} />
    </>
  )
}

export default IngresoMercaderiaPage
import React, { useEffect, useState } from 'react'

import { GetBodegaArmazonesJSON } from '../../../../function/functions'
import { ButtonToolbar, InputGroup, Form, Button,Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'ID',
        selector: row => row._id,
        reorder: true,
        omit: true,
    },

    {
        name: 'Nombre Producto',
        selector: row => row.Producto,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Stock',
        selector: row => row.Cantidad,
        sortable: true,
        reorder: true,
    },
];

const MainPage = () => {
    const [DatosArmazones, setDatosArmazones] = useState([])
    const [DataShow, setDataShow] = useState([])
    const [InfoLoad, setInfoLoad] = useState(true)
    const Navigate = useNavigate();
    useEffect(() => {
        LoadData()
    }, [])

    async function LoadData() {
        setInfoLoad(true)
        let res = await GetBodegaArmazonesJSON()
        setDatosArmazones(res.data)
        setDataShow(res.data)
        setInfoLoad(false)
        console.log(res)
    }
    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(DataShow[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }
    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV() {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(DataShow);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    async function Filtrar(e) {
        if (e.length >= 1) {
            let regex = new RegExp(escapeRegex(e), 'gi');
            let res = DatosArmazones.filter((items) => items.Producto.match(regex) || items.idProducto.match(regex) || items._id.match(regex))
            setDataShow(res)
        } else {
            setDataShow(DatosArmazones)
        }


    }
    const escapeRegex = (string: any) => {
        return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    async function Limpiar(){
        let inpFiltro = document.getElementById('Filtrador')
        inpFiltro.value=''
        setDataShow(DatosArmazones)
    }
    return (
        <>
            <Row className="rigth">
            <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                <InputGroup>
                    <Button onClick={() => downloadCSV()} variant="success">Exportar</Button>
                    
                    <Form.Control
                        type="text" onChange={(e) => Filtrar(e.target.value)}
                        placeholder="Filtrar ...."
                        id='Filtrador'
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                        className='w-full outline-none bg-transparent'
                    />
                    <Button onClick={()=>Limpiar()} variant="secondary">x</Button>
                </InputGroup>
            </ButtonToolbar>
            </Row>
            <br />
            
            <DataTable
                columns={columns}
                data={DataShow}
                progressPending={InfoLoad}
                pagination
                
                paginationComponentOptions={paginationComponentOptions}
                dense
            />

        </>
    )
}

export default MainPage
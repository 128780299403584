import React, { useState, useEffect, Fragment } from 'react'
import { ListadoLocalesGetAll } from '../../../../function/functions'
import { Row, Col, Card } from 'react-bootstrap'
const LocalesComponents = () => {
    const [Locales, setLocales] = useState([])

    useEffect(() => {
        LoadData()
    }, [])

    async function LoadData() {
        let res = await ListadoLocalesGetAll()
        console.log(res)
        setLocales(res.data)
    }
    return (
        <>
            <Row>
                {Locales.map(items =>
                    <Col xs={12} md={6} lg={4} sm={6} key={items} className='pb-1'>
                        <Card style={{ backgroundColor: items.Color }}>
                            <Card.Title>
                                <h4 className='text-white'>{items.Nombre}</h4>
                            </Card.Title>
                            <Card.Body>
                                <label className='text-white'>{items.Direccion}</label>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    )
}

export default LocalesComponents
import React, { useState, useEffect, Suspense } from 'react'

const InfiniteScroll = React.lazy(() => import('./Components/InfiniteScroll'));

const MainPage = () => {

    return (
        <div className='h-96 rounded-lg border-4 border-dashed border-gray-100 lg:h-full px-1'>
            <Suspense fallback={<div>Loading...</div>}>
                <InfiniteScroll />
            </Suspense>

        </div>
    )
}

export default MainPage 
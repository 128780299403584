import React from 'react'
import DatosLoad from './Components/InfiniteScroll'
const MainPage=()=>{
    return(
        <div className='h-96 rounded-lg border-4 border-dashed border-gray-100 lg:h-full px-1'>
            <DatosLoad/>
        </div>
    )
}
 
export default MainPage
import React, { Fragment, useState, useEffect } from 'react'
import { Modal, ListGroup, Form, Row, Col, Container, Button } from 'react-bootstrap'
import {PushProductsDB} from '../../../../../function/functions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ModalNuevoProductoDB = (props) => {

    async function CloseModal() {
        props.CloseModal()
    }

    const Ingresar = async(e) => {
        e.preventDefault()
        console.log('ejecutate')
        let Modelo = document.getElementById('Nombre').value
        let Marca = document.getElementById('Marca').value

        let formData = new FormData()
        formData.append('Modelo',Modelo.toUpperCase())
        formData.append('Marca',Marca.toUpperCase())
        let res = await PushProductsDB(formData)
        console.log(res)
        res.status===200 && AvisoSuccessAviso()
        res.status===400 && AvisoExistProduc()
    }

    async function AvisoExistProduc(){
        toast.error('El Producto Ya Esta Registrado',{
            autoClose: 2000,
            position: "top-left",
            closeOnClick :false,
            pauseOnFocusLoss : false,
            theme: "colored"
        }); 
    }
    async function AvisoSuccessAviso(){
        toast.success('El Producto ha Sido Registrado',{
            autoClose: 2000,
            position: "top-left",
            closeOnClick :false,
            pauseOnFocusLoss : false,
            onClose: ()=>CloseModal(),
            theme: "colored"
        }); 
    }


    return (
        <Modal show={props.isOpen} size="lg" onHide={(e) => CloseModal(e)}>
            <ToastContainer/>
            <Form onSubmit={(e) => Ingresar(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo Producto A Base De Datos</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Col xs={12} md={6} lg={4} className='pb-2 z-10'>
                                <Form.Control type="text" required={true} placeholder='Nombre Producto' id="Nombre" />
                            </Col>
                            <Col xs={12} md={6} lg={4} className='pb-2 z-10'>
                                <Form.Control type="text" required={true} placeholder='Marca' id="Marca" />
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant='outline-primary'>Ingresar</Button>
                    <Button type="button" variant="secondary" onClick={(e) => CloseModal(e)}>
                        Cerrar
                    </Button>

                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default ModalNuevoProductoDB
import React from 'react'

const LoginPage=()=>{
    return(
        <>
        Register Page
        </>
    )
}

export default LoginPage
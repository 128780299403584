import React, { useState, useEffect, Fragment } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { GetOnlyClient, ReajustarFecha,RemoveDocumentLC } from '../../../../function/functions'
import { Card, Button, Form } from 'react-bootstrap'
import { ExclamationTriangleIcon, NewspaperIcon, XMarkIcon } from '@heroicons/react/24/outline'

const { DateTime } = require("luxon");
const MainPage = (props) => {
    const AccoutDetail = JSON.parse(localStorage.getItem('User'))
    console.log(AccoutDetail)
    const Navigate = useNavigate();
    const [datosCliente, setDatosCliente] = useState([])
    const [ExistFichas, setExistFichas] = useState(false)
    const [ListadoFichas, setListadoFichas] = useState([])
    let { Tipo, id } = useParams();
    let location = useLocation();
    const idUser = location.state.id
    useEffect(() => {
        LoadData()
    }, [])
    async function LoadData() {
        let formData = new FormData()
        formData.append('id', idUser)
        let res = await GetOnlyClient(formData)
        console.log(res.data)
        res.status === 200 && setDatosCliente(res.data)
        res.status === 400 && setDatosCliente([])
        let Fichas = res.data.Fichas.FichasMedicas
        Fichas.length > 0 && setExistFichas(true)
        Fichas.length <= 0 && setExistFichas(false)
        setListadoFichas(Fichas)
        console.log(Fichas)
    }
    async function OpenFicha(Ficha) {
        Navigate('/Admin/Clientes/Fichas/Detalle', { state: { 'Ficha': Ficha } })
    }
    async function Filtrar(e) {

    }
    async function NuevaFicha(){
        Navigate('/Admin/Clientes/Fichas/Nueva', { state: { 'id': idUser } })
    }
    async function EliminarFicha(id){
        let formData= new FormData()
        formData.append('idClient',idUser)
        formData.append('id',id)
        //let response=await RemoveDocumentLC(formData)
        //response.status===200 && LoadData()
        

    }
    return (
        <>
            <nav aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <li>
                        <div className="flex items-center">
                            <span className="sm:ml-3">
                                <button
                                    type="button" onClick={()=>NuevaFicha()}
                                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <NewspaperIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                    Nueva Ficha
                                </button>
                            </span>

                            <svg
                                width={16}
                                height={20}
                                viewBox="0 0 16 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                className="h-5 w-4 text-gray-300"
                            >
                                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                            </svg>
                        </div>
                    </li>
                    <Form.Control type="text" className="font-medium text-gray-500 hover:text-gray-600" placeholder="Buscar" onChange={(e) => Filtrar(e.target.value)} />

                </ol>
            </nav>
            {ListadoFichas.map((item, index) =>
                <Fragment key={index}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{index+1}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <label>Motivo Consulta : {item.Anamnesis.Motivo_Consulta}</label>
                            <br/>
                            <label>Antecendentes : {item.Anamnesis.Antecedentes_Oculares}</label>
                            <br/>
                            <label>Antecedentes de Enfermedades Generales : {item.Anamnesis.Antecedentes_Enf_Generales}</label>
                            <br/>
                            <label>Otros Antecedentes : {item.Anamnesis.Otros_Antecedentes}</label>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant='outline-primary' onClick={() => OpenFicha(item)}>Ver Ficha</Button>
                            {AccoutDetail.Rol==="Desarrollador" &&
                                <Fragment>
                                    <Button type='button' variant='outline-danger' onClick={()=>EliminarFicha(item['_id'])}>Eliminar Ficha</Button>
                                </Fragment>
                            }
                        </Card.Footer>
                    </Card>
                    <br />
                </Fragment>
            )}
            {!ExistFichas &&
                <Fragment>
                    <div className="bg-red-600">
                        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
                            <div className="flex flex-wrap items-center justify-between">
                                <div className="flex w-0 flex-1 items-center">
                                    <span className="flex rounded-lg bg-red-800 p-2">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                    <p className="ml-3 truncate font-medium text-white">
                                        <span className="md:hidden">No Existen Fichas!</span>
                                        <span className="hidden md:inline">No Existen Fichas!</span>
                                    </p>
                                </div>
                                <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">

                                </div>
                                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                                    <button
                                        type="button"
                                        className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon className="h-6 hidden w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }

        </>
    )
}

export default MainPage
import React, { useState, useEffect, Fragment } from 'react'
import { Tab, Row, Form, Button, FloatingLabel, Tabs, Container, Col, Card } from 'react-bootstrap'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import LoadingPage from '../Components/ModalEventDocument'
const NuevaFichaPage = () => {
    const [OpenLoadingModal, setOpenLoadingModal] = useState(false)
    let location = useLocation();
    const idUser = location.state.id
    console.log(idUser)
    async function GenerarFicha(e) {
        e.preventDefault()
        let New_A_Motivo_Consulta = document.getElementById('New_A_Motivo_Consulta')
        let New_A_Antecedentes_Oculares = document.getElementById('New_A_Antecedentes_Oculares')
        let New_A_Antecedentes_EnfC = document.getElementById('New_A_Antecedentes_EnfC')
        let New_A_Otros_Antecedentes = document.getElementById('New_A_Otros_Antecedentes')
        let New_A_Profesional = document.getElementById('New_A_Profesional')
        let NowYear = new Date().getFullYear()
        let New_RxUso_OD_Sph = document.getElementById('New_RxUso_OD_Sph')
        let New_RxUso_OD_Cyl = document.getElementById('New_RxUso_OD_Cyl')
        let New_RxUso_OD_Axis = document.getElementById('New_RxUso_OD_Axis')
        let New_RxUso_OD_Add = document.getElementById('New_RxUso_OD_Add')
        let New_RxUso_OI_Sph = document.getElementById('New_RxUso_OI_Sph')
        let New_RxUso_OI_Cyl = document.getElementById('New_RxUso_OI_Cyl')
        let New_RxUso_OI_Axis = document.getElementById('New_RxUso_OI_Axis')
        let New_RxUso_OI_Add = document.getElementById('New_RxUso_OI_Add')
        let New_AV_Lejos_OD_SC = document.getElementById('New_AV_Lejos_OD_SC')
        let New_AV_Lejos_OD_CC = document.getElementById('New_AV_Lejos_OD_CC')
        let New_AV_Lejos_OD_PH = document.getElementById('New_AV_Lejos_OD_PH')
        let New_AV_Lejos_OI_SC = document.getElementById('New_AV_Lejos_OI_SC')
        let New_AV_Lejos_OI_CC = document.getElementById('New_AV_Lejos_OI_CC')
        let New_AV_Lejos_OI_PH = document.getElementById('New_AV_Lejos_OI_PH')
        let New_AV_Cerca_OD_SC = document.getElementById('New_AV_Cerca_OD_SC')
        let New_AV_Cerca_OD_CC = document.getElementById('New_AV_Cerca_OD_CC')
        let New_AV_Cerca_OD_PH = document.getElementById('New_AV_Cerca_OD_PH')
        let New_AV_Cerca_OI_SC = document.getElementById('New_AV_Cerca_OI_SC')
        let New_AV_Cerca_OI_CC = document.getElementById('New_AV_Cerca_OI_CC')
        let New_AV_Cerca_OI_PH = document.getElementById('New_AV_Cerca_OI_PH')
        let New_Bio_OD = document.getElementById('New_Bio_OD')
        let New_Bio_OI = document.getElementById('New_Bio_OI')
        let New_Kero_OD_K1 = document.getElementById('New_Kero_OD_K1')
        let New_Kero_OD_K2 = document.getElementById('New_Kero_OD_K2')
        let New_Kero_OD_Eje = document.getElementById('New_Kero_OD_Eje')
        let New_Kero_OI_K1 = document.getElementById('New_Kero_OI_K1')
        let New_Kero_OI_K2 = document.getElementById('New_Kero_OI_K2')
        let New_Kero_OI_Eje = document.getElementById('New_Kero_OI_Eje')
        let New_Ref_OD_Sph = document.getElementById('New_Ref_OD_Sph')
        let New_Ref_OD_Cyl = document.getElementById('New_Ref_OD_Cyl')
        let New_Ref_OD_Axis = document.getElementById('New_Ref_OD_Axis')
        let New_Ref_DNP_OD = document.getElementById('New_Ref_DNP_OD')
        let New_Ref_OD_AVLejos = document.getElementById('New_Ref_OD_AVLejos')
        let New_Ref_OD_AVCerca = document.getElementById('New_Ref_OD_AVCerca')
        let New_Ref_OD_ADD = document.getElementById('New_Ref_OD_ADD')
        let New_Ref_OI_Sph = document.getElementById('New_Ref_OI_Sph')
        let New_Ref_OI_Cyl = document.getElementById('New_Ref_OI_Cyl')
        let New_Ref_OI_Axis = document.getElementById('New_Ref_OI_Axis')
        let New_Ref_DNP_OI = document.getElementById('New_Ref_DNP_OI')
        let New_Ref_OI_AVLejos = document.getElementById('New_Ref_OI_AVLejos')
        let New_Ref_OI_AVCerca = document.getElementById('New_Ref_OI_AVCerca')
        let New_Ref_OI_ADD = document.getElementById('New_Ref_OI_ADD')
        let New_Ref_Indicaciones = document.getElementById('New_Ref_Indicaciones')
        let New_Ref_Observacion = document.getElementById('New_Ref_Observacion')
        let Anamnesis = {
            Motivo_Consulta: New_A_Motivo_Consulta.value,
            Antecedentes_Oculares: New_A_Antecedentes_Oculares.value,
            Antecedentes_Enf_Generales: New_A_Antecedentes_EnfC.value,
            Otros_Antecedentes: New_A_Otros_Antecedentes.value,
            Profesional: New_A_Profesional.value

        }
        let DatosMedicos = {
            RxUso_OD_Sph: New_RxUso_OD_Sph.value,
            RxUso_OD_Cyl: New_RxUso_OD_Cyl.value,
            RxUso_OD_Axis: New_RxUso_OD_Axis.value,
            RxUso_OD_Add: New_RxUso_OD_Add.value,
            RxUso_OI_Sph: New_RxUso_OI_Sph.value,
            RxUso_OI_Cyl: New_RxUso_OI_Cyl.value,
            RxUso_OI_Axis: New_RxUso_OI_Axis.value,
            RxUso_OI_Add: New_RxUso_OI_Add.value,
            AV_Lejos_OD_SC: New_AV_Lejos_OD_SC.value,
            AV_Lejos_OD_CC: New_AV_Lejos_OD_CC.value,
            AV_Lejos_OD_PH: New_AV_Lejos_OD_PH.value,
            AV_Lejos_OI_SC: New_AV_Lejos_OI_SC.value,
            AV_Lejos_OI_CC: New_AV_Lejos_OI_CC.value,
            AV_Lejos_OI_PH: New_AV_Lejos_OI_PH.value,
            AV_Cerca_OD_SC: New_AV_Cerca_OD_SC.value,
            AV_Cerca_OD_CC: New_AV_Cerca_OD_CC.value,
            AV_Cerca_OD_PH: New_AV_Cerca_OD_PH.value,
            AV_Cerca_OI_SC: New_AV_Cerca_OI_SC.value,
            AV_Cerca_OI_CC: New_AV_Cerca_OI_CC.value,
            AV_Cerca_OI_PH: New_AV_Cerca_OI_PH.value,
            Bio_OD: New_Bio_OD.value,
            Bio_OI: New_Bio_OI.value,
            Kero_OD_K1: New_Kero_OD_K1.value,
            Kero_OD_K2: New_Kero_OD_K2.value,
            Kero_OD_Eje: New_Kero_OD_Eje.value,
            Kero_OI_K1: New_Kero_OI_K1.value,
            Kero_OI_K2: New_Kero_OI_K2.value,
            Kero_OI_Eje: New_Kero_OI_Eje.value,
            Ref_OD_Sph: New_Ref_OD_Sph.value,
            Ref_OD_Cyl: New_Ref_OD_Cyl.value,
            Ref_OD_Axis: New_Ref_OD_Axis.value,
            Ref_DNP_OD: New_Ref_DNP_OD.value,
            Ref_DNP_OI: New_Ref_DNP_OI.value,
            Ref_OD_AVLejos: New_Ref_OD_AVLejos.value,
            Ref_OD_AVCerca: New_Ref_OD_AVCerca.value,
            Ref_OI_Sph: New_Ref_OI_Sph.value,
            Ref_OI_Cyl: New_Ref_OI_Cyl.value,
            Ref_OI_Axis: New_Ref_OI_Axis.value,
            Ref_OI_AVLejos: New_Ref_OI_AVLejos.value,
            Ref_OI_AVCerca: New_Ref_OI_AVCerca.value,
            Ref_OD_ADD: New_Ref_OD_ADD.value,
            Ref_OI_ADD: New_Ref_OI_ADD.value,
            Ref_Indicaciones: New_Ref_Indicaciones.value,
            Ref_Observacion: New_Ref_Observacion.value
        }
        let formData = new FormData();
        formData.append('Anamnesis', JSON.stringify(Anamnesis))
        formData.append('DatosMedicos', JSON.stringify(DatosMedicos))
        formData.append('Anno', NowYear)
        console.log(formData)
        setOpenLoadingModal(true)
    }
    async function CerrarModalLoading() {
        setOpenLoadingModal(false)


    }
    return (
        <>
            <form onSubmit={(e) => GenerarFicha(e)} >
                <Tabs
                    defaultActiveKey="Anamnesis"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="Anamnesis" title="Anamnesis">
                        <Row>
                            <FloatingLabel
                                label="Motivo Consulta"
                                className="mb-3"
                            >
                                <Form.Control type="text" required={true} name='New_A_Motivo_Consulta' id="New_A_Motivo_Consulta" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="Antecedentes Generales"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" style={{ height: '200px' }} name="New_A_Antecedentes_Oculares" id="New_A_Antecedentes_Oculares" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="Antecedentes Enfermedades Oculares"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" style={{ height: '200px' }} name="New_A_Antecedentes_EnfC" id="New_A_Antecedentes_EnfC" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="Otros Antecedentes"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" style={{ height: '200px' }} name="New_A_Otros_Antecedentes" id="New_A_Otros_Antecedentes" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="Profesional"
                                className="mb-3"
                            >
                                <Form.Control type='text' required={true} name="New_A_Profesional" id="New_A_Profesional" />
                            </FloatingLabel>
                        </Row>
                        <br />
                        <br />
                    </Tab>
                    <Tab eventKey="Datos_Medicos" title="Datos Medicos">
                        <h3>Rx En Uso</h3>
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={1}>OD</Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Sph"
                                        className="mb-3"
                                    >
                                        <Form.Control type='number' step={0.25} name="New_RxUso_OD_Sph" id="New_RxUso_OD_Sph" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Cyl"
                                        className="mb-3"
                                    >
                                        <Form.Control type='number' step={0.25} name="New_RxUso_OD_Cyl" id="New_RxUso_OD_Cyl" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Axis"
                                        className="mb-3"
                                    >
                                        <Form.Control type='number' min={0} max={180} name="New_RxUso_OD_Axis" id="New_RxUso_OD_Axis" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Add"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' name="New_RxUso_OD_Add" id="New_RxUso_OD_Add" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="DNP"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' name="New_RxUso_OD_DNP" id="New_RxUso_OD_DNP" />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={12} md={6} lg={1}>OI</Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Sph"
                                        className="mb-3"
                                    >
                                        <Form.Control type='number' step={0.25} name="New_RxUso_OI_Sph" id="New_RxUso_OI_Sph" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Cyl"
                                        className="mb-3"
                                    >
                                        <Form.Control type='number' step={0.25} name="New_RxUso_OI_Cyl" id="New_RxUso_OI_Cyl" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Axis"
                                        className="mb-3"
                                    >
                                        <Form.Control type='number' min={0} max={180} name="New_RxUso_OI_Axis" id="New_RxUso_OI_Axis" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Add"
                                        className="mb-3"
                                    >
                                        <Form.Control type='number' step={0.25} min={0} name="New_RxUso_OI_Add" id="New_RxUso_OI_Add" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="DNP"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' name="New_RxUso_OI_DNP" id="New_RxUso_OI_DNP" />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Container>
                        <hr />
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    AV Lejos
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col xs={12} md={6} lg={1}>OD</Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="SC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Lejos_OD_SC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="CC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Lejos_OD_CC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="PH"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Lejos_OD_PH" />
                                            </FloatingLabel>
                                        </Col>

                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={12} md={6} lg={1}>OI</Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="SC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Lejos_OI_SC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="CC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Lejos_OI_CC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="PH"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Lejos_OI_PH" />
                                            </FloatingLabel>
                                        </Col>

                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                        <br />
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    AV Cerca
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col xs={12} md={6} lg={1}>OD</Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="SC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Cerca_OD_SC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="CC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Cerca_OD_CC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="PH"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Cerca_OD_PH" />
                                            </FloatingLabel>
                                        </Col>

                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={12} md={6} lg={1}>OI</Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="SC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Cerca_OI_SC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="CC"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Cerca_OI_CC" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <FloatingLabel
                                                label="PH"
                                                className="mb-3"
                                            >
                                                <Form.Control type='text' id="New_AV_Cerca_OI_PH" />
                                            </FloatingLabel>
                                        </Col>

                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                        <hr />
                        <h3>Biomicroscopia</h3>
                        <Row>
                            <FloatingLabel
                                label="OD"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" style={{ height: '100px' }} name='New_Bio_OD' id="New_Bio_OD" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="OI"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" style={{ height: '100px' }} name="New_Bio_OI" id="New_Bio_OI" />
                            </FloatingLabel>

                        </Row>
                        <hr />
                        <h3>Keratometria</h3>
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={1}>OD</Col>
                                <Col xs={12} md={6} lg={3}>
                                    <FloatingLabel
                                        label="Sph"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Kero_OD_K1" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <FloatingLabel
                                        label="Cyl"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Kero_OD_K2" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <FloatingLabel
                                        label="Axis"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Kero_OD_Eje" />
                                    </FloatingLabel>
                                </Col>

                            </Row>
                            <br />
                            <Row>
                                <Col xs={12} md={6} lg={1}>OI</Col>
                                <Col xs={12} md={6} lg={3}>
                                    <FloatingLabel
                                        label="Sph"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Kero_OI_K1" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <FloatingLabel
                                        label="Cyl"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Kero_OI_K2" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <FloatingLabel
                                        label="Axis"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Kero_OI_Eje" />
                                    </FloatingLabel>
                                </Col>

                            </Row>
                        </Container>
                        <hr />
                        <h3>Refraccion</h3>
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={1}>OD</Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Sph"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OD_Sph" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Cyl"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OD_Cyl" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Axis"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OD_Axis" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="DNP"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_DNP_OD" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="AV Lejos"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OD_AVLejos" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="AV Cerca"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OD_AVCerca" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Add"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OD_ADD" />
                                    </FloatingLabel>
                                </Col>

                            </Row>
                            <br />
                            <Row>
                                <Col xs={12} md={6} lg={1}>OI</Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Sph"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OI_Sph" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Cyl"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OI_Cyl" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Axis"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OI_Axis" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="DNP"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_DNP_OI" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="AV Lejos"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OI_AVLejos" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="AV Cerca"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OI_AVCerca" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <FloatingLabel
                                        label="Add"
                                        className="mb-3"
                                    >
                                        <Form.Control type='text' id="New_Ref_OI_ADD" />
                                    </FloatingLabel>
                                </Col>

                            </Row>
                        </Container>
                        <Row>
                            <FloatingLabel
                                label="Indicaciones"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" style={{ height: '200px' }} name="New_Ref_Indicaciones" id="New_Ref_Indicaciones" />
                            </FloatingLabel>
                            <FloatingLabel
                                label="Observaciones"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" style={{ height: '200px' }} name="New_Ref_Observacion" id="New_Ref_Observacion" />
                            </FloatingLabel>
                        </Row>

                    </Tab>
                </Tabs>
                <Button variant='danger' className={`btn-flotante ${OpenLoadingModal && `invisible`}`} type='submit'>Actualizar</Button>
            </form>
            <LoadingPage isOpen={OpenLoadingModal} CloseModal={() => CerrarModalLoading()} />
            <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          Get free delivery on orders over $100
        </p>
        </>
    )
}

export default NuevaFichaPage
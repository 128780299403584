import React, { useState, useEffect, Fragment } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Row, ListGroup, Badge, FloatingLabel, Form } from 'react-bootstrap'
import { GetBodegaCristalesJSON } from '../../../../../function/functions'
import { ExclamationTriangleIcon, NewspaperIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
const InfiniteScrollComponent = () => {
    const [ListadoCristales, setListadoCristales] = useState([])
    const Navigate = useNavigate();
    const [items, setItems] = useState([])
    useEffect(() => {
        LoadDate()
        console.log('useEffect')
    }, [])
    async function LoadDate() {
        let res = await GetBodegaCristalesJSON()
        console.log(res)
        setListadoCristales(res.data)
        await CargarDatos(res.data)
    }
    async function CargarDatos(data) {
        let datos = data
        let dat = []
        let min = 0
        let max = 20
        for (let i = min; i <= max; i++) {
            dat.push(datos[i])

        }
        setItems(dat)
    } 
    async function CargarInformacion() {
        console.log(2)
        let NewItems = []
        let currentLent = items.length
        let next = items.length + 20
        for (let i = currentLent; i <= next; i++) {
            NewItems.push(ListadoCristales[i])
        }
        let conform = items.concat(NewItems)
        setItems(conform)
    }

    const refreshFunc = () => {
        let getFilter = document.getElementById('Buscador')
        getFilter.value = ''
        CargarInformacion()
    }
    
    
    async function Filtrar(e) {
        if (e.length >= 1) {
            console.log(e)
            let regex = new RegExp(escapeRegex(e), 'gi');
            let res = items.filter((items) =>  items.QR.match(regex) || items.Material.match(regex) || items.Cyl.match(regex) || items.Sph.match(regex))
            console.log(res)
            if (res.length > 0) {
                setItems(res)
            } else {
                setItems([])
            }
        } else {
            await LoadDate()
        }
    }
    const escapeRegex = (string) => {
        return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    };
    
    async function NuevoCristal() {

    }
    return (
        <>
            <Row>
                <nav aria-label="Breadcrumb">
                    <ol role="list" className="mx-auto flex max-w-6xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        <li>
                            <div className="flex items-center">
                                <span className="sm:ml-3">
                                    <button
                                        type="button" onClick={() => NuevoCristal()}
                                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        <NewspaperIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                        Nuevo Cristal
                                    </button>
                                </span>

                                <svg
                                    width={16}
                                    height={20}
                                    viewBox="0 0 16 20"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    className="h-5 w-4 text-gray-300"
                                >
                                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                </svg>
                            </div>

                        </li>
                        <Form.Control type="text" className='className="font-medium text-gray-500 hover:text-gray-600' size='sm' id="Buscador" placeholder="Buscar" onChange={(e) => Filtrar(e.target.value)} />
                    </ol>
                </nav>
            </Row>

            <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={CargarInformacion}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
                // below props only if you need pull down functionality
                refreshFunction={refreshFunc}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                }
                releaseToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                }
            >
                {items.map((item, index) =>
                    <Fragment key={index}>
                        <Card >
                            <Card.Header>
                                <Card.Title>
                                    {item.QR} 
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                            {item.Material} {item.Sph} {item.Cyl}
                            <br/>
                            Rango : {item.Rango}
                            <br/> 
                            Stock : {item.Cantidad} 
                            </Card.Body>
                            <Card.Footer>
                            {item.LastUpdate} 
                            </Card.Footer>
                        </Card>
                        <br />
                    </Fragment>
                )}
            </InfiniteScroll>
        </>
    )
}

export default InfiniteScrollComponent
import React, { useState, useEffect } from 'react'
import { getConvenios, SearchExcel } from '../../../function/functions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ExcelPage = () => {
    const [ArrayAnnos, setArrayAnnos] = useState([])
    const [ListaConvenios, setListaConvenios] = useState([])
    const [ListadoExcel, setListadoExcel] = useState([])
    const MinYear = 2020
    const ListadoConveniosAutorizados = [
        {
            Sigla: 'HGF',
            Nombre: 'HOSPITAL DR. GUSTAVO FRICKE'
        },
        {
            Sigla: 'HCVB',
            Nombre: 'HOSPITAL DR. CARLOS VAN BUREN'
        }, {
            Sigla: 'HQ',
            Nombre: 'HOSPITAL DE QUILPUE'
        }, {
            Sigla: 'MLL',
            Nombre: 'I. MUNICIPALIDAD DE LA LIGUA'
        },
        {
            Sigla: 'RESLI',
            Nombre: 'RESOLUTIVIDAD LIMACHE'
        },
        {
            Sigla: 'HLI',
            Nombre: 'HOSPITAL DE LIMACHE'
        },
        {
            Sigla: 'SSV',
            Nombre: 'SERVICIO SALUD VALPARAISO'
        },
        {
            Sigla: 'LACAL',
            Nombre: 'HOSPITAL DE LA CALERA'
        }
    ]
    async function CargarAnnos() {

        let Anno = new Date().getFullYear()
        let ListAnn = []
        for (let i = MinYear; i <= parseInt(Anno); i++) {
            ListAnn.push(i)
        }
        // console.log(ListAnn)
        setArrayAnnos(ListAnn.reverse())
    }
    async function CargarConvenios() {
        let response = await getConvenios()
        response.status === 200 && setListaConvenios(response.data)
        response.status >= 400 && setListaConvenios([])
        console.log(response)
    }
    useEffect(() => {
        CargarAnnos()
        CargarConvenios()
        setTimeout(() => {
            LoadData()
        }, 1000);
    }, [])

    async function LoadData() {
        let Anno = document.getElementById('YearSelect')
        console.log(Anno.value)
        let Conv = document.getElementById('ConvenioSelect')
        console.log(Conv.value)
        let formData = new FormData()
        formData.append('Anno', Anno.value)
        formData.append('Sigla', Conv.value)
        let res = await SearchExcel(formData)
        res.status === 200 && res.data.length >= 1 && NotifyDataLoad()
        res.status === 200 && res.data.length === 0 && NotifyNoData()
        console.log(res)
    }
    const NotifyNoData=()=>{
        toast.error('Error. No Existe Registro de Esta Planilla Excel',{
            autoClose: 2000,
            closeOnClick :false,
            pauseOnFocusLoss : false,
            toastId : 'ErrorID',
            theme: "colored"
        }); 
    }
    const NotifyDataLoad=()=>{
        toast.success('Cargando ...',{
            autoClose: 2000,
            closeOnClick :false,
            pauseOnFocusLoss : false,
            toastId: "LoadID",
            theme: "colored"
        }); 
    } 

    return (
        <>
            <div
                className="flex items-center gap-x-5"
                aria-label="button-combination"
            >
                <select className="w-full outline-none bg-transparent" id="YearSelect">
                    {ArrayAnnos.map((item, index) =>
                        <option key={index} value={item} >{item}</option>
                    )}
                </select>
                <select className="w-full outline-none bg-transparent" id="ConvenioSelect">
                    {ListadoConveniosAutorizados.map((item, index) =>
                        <option key={index} value={item['Sigla']} >{item['Sigla']} - {item['Nombre']}</option>
                    )}
                </select>


                <button type="button" onClick={() => LoadData()} className="inline-flex items-center justify-center px-8 py-4 font-sans font-semibold tracking-wide text-blue-500 border border-blue-500 rounded-lg h-[60px]">
                    Filtrar
                </button>
            </div>
            <ToastContainer/> 
        </>
    )
}

export default ExcelPage
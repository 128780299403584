import React, { useState, useEffect, Fragment } from 'react'
import { Tab, Row, Form, Button, FloatingLabel, Tabs, Container, Col, Card } from 'react-bootstrap'
import { ExclamationTriangleIcon, NewspaperIcon, XMarkIcon } from '@heroicons/react/24/outline'
import ModalNuevoControl from '../Components/ModalNuevoControl'
import './Detail.css'
import { GetFolioJunaeb, InsertDocumentLC } from '../../../../function/functions'
import Loading from '../Components/ModalEventDocument'
import { useParams, useLocation,useNavigate  } from 'react-router-dom'
const NuevaFichaPage = () => {
    const [Controles, setControles] = useState([])
    const [OpenModalNewControl, setOpenModalNewControl] = useState(false)
    const [OpenLoadingModal, setOpenLoadingModal] = useState(false)
    let { Tipo, id } = useParams();
    const navigate = useNavigate();
    let location = useLocation();
    const idUser = location.state.id
    async function NuevoControl() {
        setOpenModalNewControl(true)
    }
    const CerrarNuevoModal = () => setOpenModalNewControl(false)
    async function NewPushControl(newControl) {
        let cont = Controles
        cont.push(newControl)
        setControles(cont)
        setOpenModalNewControl(false)
    }
    const RegistrarAtencion = async (e) => {
        setOpenLoadingModal(true)
        e.preventDefault()
        let Folio = 0
        if (Tipo === 'Junaeb') {
            Folio = await GetFolioJunaeb()
            Folio = 'J ' + Folio
        } else {
            let GetOT = document.getElementById('New_OT')
            Folio = GetOT.value
        }
        console.log(Folio)


        GenerarDocumento(Folio)
    }

    async function GenerarDocumento(Folio) {
        let fech = document.getElementById('New_DA_Fecha')
        let fecha_Generada = fech.value
        let get_DatosAtencion_Detalle = document.getElementById('New_DA_Detalle')
        let get_DatosAtencion_MarcaLC = document.getElementById('New_DA_MarcaLC')
        let get_DatosAtencion_OD = document.getElementById('New_DA_OD')
        let get_DatosAtencion_AVODLejos = document.getElementById('New_DA_AVODLejos')
        let get_DatosAtencion_AVODCerca = document.getElementById('New_DA_AVODCerca')
        let get_DatosAtencion_ODCB = document.getElementById('New_DA_CurvaBaseOD')
        let get_DatosAtencion_ODDiam = document.getElementById('New_DA_DiametroOD')
        let get_DatosAtencion_OI = document.getElementById('New_DA_OI')
        let get_DatosAtencion_AVOILejos = document.getElementById('New_DA_AVOILejos')
        let get_DatosAtencion_AVOICerca = document.getElementById('New_DA_AVOICerca')
        let get_DatosAtencion_OICB = document.getElementById('New_DA_CurvaBaseOI')
        let get_DatosAtencion_OIDiam = document.getElementById('New_DA_DiametroOI')
        let get_DatosAtencion_AVDetalle = document.getElementById('New_DA_AVDetalle')
        let get_DatosAtencion_Profesiona = document.getElementById('New_DA_Profesional')
        let get_DatosEntrega_Fecha = document.getElementById('New_DE_Fecha')
        let get_DatosEntrega_Profesiona = document.getElementById('New_DE_Profesional')
        let get_DatosEntrega_Detalle = document.getElementById('New_DE_Detalle')
        let formData = new FormData()
        let data = {
            OT: Folio,
            Anno: fecha_Generada.split('-')[0],
            Tipo: Tipo,
            DatosAtencion: {
                Fecha: fech.value,
                DetalleAtencion: get_DatosAtencion_Detalle.value,
                MarcaLC: get_DatosAtencion_MarcaLC.value,
                OD: get_DatosAtencion_OD.value,
                AVODLejos: get_DatosAtencion_AVODLejos.value,
                CurvaBaseOD: get_DatosAtencion_ODCB.value,
                CurvaBaseOI: get_DatosAtencion_OICB.value,
                DiametroOD: get_DatosAtencion_ODDiam.value,
                DiametroOI: get_DatosAtencion_OIDiam.value,
                AVODCerca: get_DatosAtencion_AVODCerca.value,
                OI: get_DatosAtencion_OI.value,
                AVOILejos: get_DatosAtencion_AVOILejos.value,
                AVOICerca: get_DatosAtencion_AVOICerca.value,
                AVDetalle: get_DatosAtencion_AVDetalle.value,
                Profesional: get_DatosAtencion_Profesiona.value
            },
            DatosEntrega: {
                Fecha: get_DatosEntrega_Fecha.value,
                Profesional: get_DatosEntrega_Profesiona.value,
                Detalle: get_DatosEntrega_Detalle.value
            },
            DatosControles: Controles
        }
        //console.log(data)
        formData.append('Data', JSON.stringify(data))
        formData.append('idUser', idUser)
        let response = await InsertDocumentLC(formData)
        setTimeout(() =>{
            response.status === 200 && setOpenLoadingModal(false)
            response.status === 400 && setOpenLoadingModal(false)
        }, 3000)
        setTimeout(()=>{
            navigate(-1)
        },1000)
    }
    async function CerrarModalLoading() {
        setOpenLoadingModal(false)
        

    }
    return (
        <>
            <form onSubmit={(e) => RegistrarAtencion(e)}>
                <Tabs
                    defaultActiveKey="DetalleAtencion"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="DetalleAtencion" title="Detalle Atencion">
                        {Tipo === 'Particular' &&
                            <Fragment>
                                <Row>
                                    <FloatingLabel
                                        label="OT"
                                        className="mb-3"
                                    >
                                        <Form.Control required={true} type="text" id="New_OT" />
                                    </FloatingLabel>

                                </Row>
                            </Fragment>
                        }
                        <Row>
                            <FloatingLabel
                                label="Fecha Atencion"
                                className="mb-3"
                            >
                                <Form.Control type="date" required={true} id="New_DA_Fecha" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label={`Detalle`}
                                className="mb-3"
                            >
                                <Form.Control as="textarea" id="New_DA_Detalle" style={{ height: '300px' }} />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="Marca Lente de Contacto"
                                className="mb-3"
                            >
                                <Form.Control type="text" id="New_DA_MarcaLC" />
                            </FloatingLabel>

                        </Row>
                        <Row className="text-left">
                            <Form.Label htmlFor="inputPassword5">
                                OD
                            </Form.Label>

                        </Row>
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="Curva Base"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_CurvaBaseOD" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="Potencia"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_OD" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="Diametro"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_DiametroOD" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="AV Lejos"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_AVODLejos" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="AV Cerca"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_AVODCerca" />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Container>
                        <Row className="text-left">
                            <Form.Label htmlFor="inputPassword5">OI</Form.Label>

                        </Row>
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="Curva Base"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_CurvaBaseOI" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="Potencia"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_OI" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="Diametro"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_DiametroOI" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="AV Lejos"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_AVOILejos" />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <FloatingLabel
                                        label="AV Cerca"
                                        className="mb-3"
                                    >
                                        <Form.Control type="text" id="New_DA_AVOICerca" />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Container>
                        <Row>
                            <FloatingLabel
                                label={`Av Detalle`}
                                className="mb-3"
                            >
                                <Form.Control type='text' id="New_DA_AVDetalle" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="Profesional"
                                className="mb-3"
                            >
                                <Form.Control type="text" id="New_DA_Profesional" />
                            </FloatingLabel>

                        </Row>
                    </Tab>
                    <Tab eventKey="DetalleEntrega" title="Detalle Entrega">
                        <Row>
                            <FloatingLabel
                                label="Fecha Entrega"
                                className="mb-3"
                            >
                                <Form.Control type="date" id="New_DE_Fecha" />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label={`Información`}
                                className="mb-3"
                            >
                                <Form.Control as="textarea" id="New_DE_Detalle" style={{ height: '300px' }} />
                            </FloatingLabel>

                        </Row>
                        <Row>
                            <FloatingLabel
                                label="Profesional"
                                className="mb-3"
                            >
                                <Form.Control type="text" id="New_DE_Profesional" />
                            </FloatingLabel>

                        </Row>
                    </Tab>
                    <Tab eventKey="Controles" title="Controles">
                        <nav aria-label="Breadcrumb">
                            <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                                <li>
                                    <div className="flex items-center">
                                        <span className="sm:ml-3">
                                            <button
                                                type="button" onClick={() => NuevoControl()}
                                                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                <NewspaperIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                Nuevo Control
                                            </button>
                                        </span>

                                        <svg
                                            width={16}
                                            height={20}
                                            viewBox="0 0 16 20"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            className="h-5 w-4 text-gray-300"
                                        >
                                            <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                        </svg>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        {Controles.map((item, index) =>
                            <Fragment key={index}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>
                                            Control N° {index + 1}
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <FloatingLabel
                                                label="Fecha Control"
                                                className="mb-3"
                                            >
                                                <Form.Control size="sm" type="date" defaultValue={item['Fecha']} />
                                            </FloatingLabel>

                                        </Row>
                                        <Row>
                                            <FloatingLabel
                                                label="Detalle"
                                                className="mb-3"
                                            >
                                                <Form.Control size="sm" type="text" defaultValue={item['Detalle']} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <FloatingLabel
                                                label="Fecha Control"
                                                className="mb-3"
                                            >
                                                <Form.Control size="sm" type="text" defaultValue={item['Profesional']} />
                                            </FloatingLabel>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button type="button" variant='danger'>Eliminar</Button>
                                    </Card.Footer>
                                </Card>
                            </Fragment>
                        )}
                        <ModalNuevoControl isOpen={OpenModalNewControl} NuevoControl={(item) => NewPushControl(item)} CloseModal={CerrarNuevoModal} />
                    </Tab>



                </Tabs>
                <br />
                <br />
                <Button variant='danger' className='btn-flotante' type='submit'>Actualizar</Button>
            </form>
            <Loading isOpen={OpenLoadingModal} CloseModal={() => CerrarModalLoading()} />
        </>
    )
}

export default NuevaFichaPage
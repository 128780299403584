import React, { useState, useEffect, Fragment } from 'react'
import { getConvenios, GetBodegaArmazonesJSON, RebajarProductosMermasGarantiasBodegaArmazones } from '../../../../function/functions'
import Autosuggest from 'react-autosuggest'
import { Container, Row, Col, Button, Form, Table } from 'react-bootstrap'
import './Style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import Proccess from '../../../component/Proccess'
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux'
const MermasGarantiasPage = ()=>{
  const [ShowProgress, setShowProgress] = useState(false)
  const [DbProductos, setDbProductos] = useState([])
  const [ListaProductos, setListaProductos] = useState([])
  const [ListaConvenios, setListaConvenios] = useState([])
  const [ProductoSeleccionado, setProductoSeleccionado] = useState([])
  const [ListaSalida, setListaSalida] = useState([])
  const Navigate = useNavigate();
  const [value, setValue] = useState("");
  const RebajadoPor = useSelector(state => state.AccountState.userInfo.Nombre)
  useEffect(() => {
    LoadData()
  }, [])
  async function LoadData() {
    let res = await getConvenios()
    setListaConvenios(res.data)
    let resDB = await GetBodegaArmazonesJSON()
    setListaProductos(resDB.data)
    setDbProductos(resDB.data)
  }
  const onSuggestionsFetchRequested = async ({ value }) => {
    Filtrar(value)
  }
  async function Filtrar(e) {
    if (e.length >= 1) {
      let regex = new RegExp(escapeRegex(e), 'gi');
      let res = DbProductos.filter((items) => items.Producto.match(regex))
      setListaProductos(res.slice(0, 10))
    } else {
      setListaProductos([])
    }
  }
  const escapeRegex = (string) => {
    return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };
  const onSuggestionsClearRequested = () => {
    setListaProductos([]);
  }
  const getSuggestionValue = (suggestion) => {
    return `${suggestion.Producto}`;
  }
  const renderSuggestion = (suggestion) => (
    <div className='sugerencia' onClick={() => seleccionarPresidente(suggestion)}>
      {`${suggestion.Producto} - Stock : ${suggestion.Cantidad}`}
    </div>
  );
  const seleccionarPresidente = (Producto) => {
    setProductoSeleccionado(Producto);
  }
  const onChange = (e, { newValue }) => {
    setValue(newValue);
  }
  const inputProps = {
    placeholder: "Ingrese Un Producto",
    value,
    id: 'Filtrador',
    required: true,
    className: 'w-full',
    onChange
  };
  const eventEnter = (e, { newValue }) => {
    console.log(e.target)
    if (e.key === "Enter") {
      var split = e.target.value.split('-');
      var Producto = {
        Nombre: split[0].trim(),
        Marca: split[1].trim(),
      };
      seleccionarPresidente(Producto);
    }
  }
  const Ingresar = async (e) => {
    e.preventDefault()
    if (ProductoSeleccionado._id) {
      let NowYear = new Date().getFullYear()
      let containerObject = []
      let NewProduct = ''
      let Cantidad = document.getElementById('Cantidad').value
      let Motivo = document.getElementById('Motivo').value
      
        NewProduct = {
          uid : uuidv4(),
          idBodega: ProductoSeleccionado._id,
          idProducto : ProductoSeleccionado.idProducto,
          Anno: NowYear,
          Producto: ProductoSeleccionado.Producto,
          Cantidad: Cantidad,
          Motivo: Motivo,
          SacadoPor : RebajadoPor
        }
        
      let List = ListaSalida.concat(NewProduct)
      setListaSalida(List)
      console.log(NewProduct)
      console.log(List)
      
      let currentOT = document.getElementById('Cantidad')
      currentOT.value = 1
      let currentLugar = document.getElementById('Motivo')
      currentLugar.value =''
      setProductoSeleccionado([])

    } else {
      ErrorAutocomplete()
    }









  }
  const ErrorAutocomplete = () => {
    toast.error('Seleccione un Producto Valido Del Autocompletado', {
      position: "top-left",
      autoClose: 2000,
      closeOnClick: false,
      pauseOnFocusLoss: false,
      theme: "colored"
    });
  }
  async function EliminarItem(indice) {
    let Array = ListaSalida.filter((item, index) => index !== indice)
    console.log(Array)
    setListaSalida(Array)
  } 
  async function SalidaMercaderia() {
    setShowProgress(true)
    let formData = new FormData()
    
    formData.append('Salidas', JSON.stringify(ListaSalida))
    formData.append('RebajadoPor',RebajadoPor)
    let res = await RebajarProductosMermasGarantiasBodegaArmazones(formData)
    if (res.status === 200) {
      let ListaRemover = res.data.ListaRemover
      console.log(ListaRemover)
      let NuevaList = ListaSalida
      for (let y = 0; y < ListaRemover.length; y++) {


        NuevaList = NuevaList.filter((item, index) => item.uid !== ListaRemover[y].id )

      }
      setListaSalida(NuevaList)
      let sucessSalidas = res.data.Sucess
      for (let z = 0; z < sucessSalidas.length; z++) {
        SuccessNotify(sucessSalidas[z])
      }
      let ErrorSalidas = res.data.Error
      for (let x = 0; x < ErrorSalidas.length; x++) {
        ErrorNotify(ErrorSalidas[x])
      }
    }
    setTimeout(() => {
      setShowProgress(false)
      LoadData()
    }, 3000)

  }
  const ErrorNotify = (message) => {
    toast.error(message, {
      position: "top-left",
      autoClose: 2000,
      closeOnClick: false,
      pauseOnFocusLoss: false,
      theme: "colored"
    });
  }
  const SuccessNotify = (message) => {
    toast.success(message, {
      closeOnClick: false,
      autoClose: 2000,
      pauseOnFocusLoss: false,
      theme: "colored"
    });
  }
  
  return (
    <>

      <br />
      <Form onSubmit={(e) => Ingresar(e)}>
        <ToastContainer />
        <Container>
          <Row>
            <Col xs={12} md={5} lg={4} className='pb-2 z-10'>
              <Autosuggest
                id="Filtrador"
                suggestions={ListaProductos}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={eventEnter}
              />
            </Col>
            <Col xs={12} md={2} lg={2} className='pb-2'>
              <input type="number" id="Cantidad" className='w-full' defaultValue={1} />
            </Col>
            <Col xs={12} md={3} lg={3} className='pb-2'>
              <input type='text' id="Motivo" placeholder='Motivo y OT' className='w-full' required={true} />
            </Col>
            <Col xs={12} md={1} lg={1} className='pb-2'>
              <Button variant='primary' type="submit">Seleccionar</Button>
            </Col>
          </Row>
        </Container>
        <Container>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <td>Producto</td>
                <td>Cantidad</td>
                <td>Lugar</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {ListaSalida.map((item, index) =>
                <tr key={index}>
                  <td>{item.Producto}</td>
                  <td>{item.Cantidad}</td>
                  <td>{item.Motivo}</td>
                  <td><Button type="button" variant='danger' size="sm" onClick={() => EliminarItem(index)}>Eliminar</Button></td>
                </tr>
              )}
            </tbody>
            {ListaSalida.length >= 1 &&
              <Fragment>
                <tfoot>
                  <tr>
                    <td colSpan='4'>
                      <Button type="button" onClick={() => SalidaMercaderia()} variant='primary' size="sm">Ingresar</Button>
                    </td>
                  </tr>
                </tfoot>
              </Fragment>

            }
          </Table>

        </Container>
      </Form>
      <Proccess isOpen={ShowProgress} CloseModal={() => setShowProgress(false)} />
    </>
  )
}

export default MermasGarantiasPage
import React, { useState,useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listDay from '@fullcalendar/list'
import listWeek from '@fullcalendar/list'
import listYear from '@fullcalendar/list'
import list from '@fullcalendar/list'
import { Button, Modal, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import {CargarEventosCalendario,ModificarEventosCalendario} from '../../../../function/functions'



const CalendarComponent = () => {
    const [Events, setEvents] = useState([])
    const customId = "custom-id-yes";
    const [Eventos,setEventos]=useState([])
    const [AbrirModalNuevoEvento,setAbrirModalNuevoEvento]=useState(false)
    const [AbrirModalEditarEvento,setAbrirModalEditarEvento]=useState(false)
    const [DataNewEvent,setDataNewEvent]=useState([])
    const [DataModEvent,setDataModEvent]=useState([])
    useEffect(()=>{
        CargarEventos(1)
    },[])

    const CargarEventos=async()=>{
        let res= await CargarEventosCalendario()
        setEventos(res)
    }
    const OpenNuevoModal = ()=>setAbrirModalNuevoEvento(true)
    const CerrarNuevoModal = ()=>{
        setAbrirModalNuevoEvento(false)
        CargarEventos(1)
    }
    const OpenModificarModal =()=>setAbrirModalEditarEvento(true)
    const CerrarEditarModal = ()=>{
        setAbrirModalEditarEvento(false)
        CargarEventos(1)
    }
 
    function RangoSeleccionado(e){
        let Data
        if(e.startStr.length===10){
            Data = {
                allDay : e.allDay,
                allDaySelect : true,
                Inicio : e.startStr,
                Fin : e.endStr
            }
        }else{
            Data = {
                allDay : '',
                allDaySelect : false,
                Inicio : e.startStr,
                Fin : e.endStr
            }
            setDataNewEvent(Data)
            OpenNuevoModal()
        }
        
    }
    async function ModificarEvento(e){
        const DatosModificar = {
            start : e.event.startStr,
            end : e.event.endStr,
            _id : e.event.extendedProps._id
        }
        await ModificarEventosCalendario(DatosModificar)
        
    }
    function ModificarDatosEvento (e){
        setDataModEvent(e.event.extendedProps)
        console.log(e.event.extendedProps)
        OpenModificarModal()
    }
    return (
        <>
            <FullCalendar
                plugins={[bootstrapPlugin, timeGridPlugin, dayGridPlugin, list, listDay, listYear, listWeek, interactionPlugin]}
                initialView="timeGridWeek"
                headerToolbar={{
                    left: 'title',
                    right: 'prevYear prev today next nextYear timeGridDay timeGridWeek dayGridMonth listWeek myCustomButton'
                }} 

                titleFormat={{
                    day: 'numeric', month: 'long', year: 'numeric'
                }}
                handleWindowResize='true'
                locale={'esLocale'}
                firstDay={'1'}
                hiddenDays={'0'}
                allDaySlot={true}
                nowIndicator={'true'}
                buttonText={{
                    today: 'Hoy',
                    month: 'Mes',
                    list: 'Lista De Eventos',
                    day: 'Dia',
                    week: 'Semana',

                }}
                customButtons={{
                    myCustomButton: {
                        text: 'Actualizar Calendario',
                        click: function () {
                            CargarEventos()
                        }
                    }
                }}
                buttonIcons={{
                    prev: 'chevron-left',
                    next: 'chevron-right',
                    prevYear: 'chevrons-left', // double chevron
                    nextYear: 'chevrons-right' // double chevron
                }}
                navLinks={'true'}
                events={Events}
                themeSystem={'bootstrap5'}

                windowResizeDelay={'100'}
                weekNumbers={'true'}
                select={
                    e => RangoSeleccionado(e)
                }
                eventDrop={e => ModificarEvento(e)
                }
                eventResize={e => ModificarEvento(e)
                }
                eventClick={e => ModificarDatosEvento(e)
                }

                slotDuration='00:05:00'
                slotMinTime='09:00:00'
                slotMaxTime='20:00:00'

                selectable='true'

            />
            
        </>
    )
}

export default CalendarComponent
import axios from 'axios'
let backend = process.env.REACT_APP_BACKEND_URL
let Token = localStorage.getItem('Token')

export const Login = async (data) => {
    let response = []
    await axios.post(backend + '/api/auth0/Login/Verified', data)
        .then(res => {
            response = res
        })
        .catch((error) => {
            response = error.response

        })
    return response

}
// Get Locales
export const ListadoLocalesGetAll = async () => {
    let response = []
    await axios.post(backend + '/api/auth0/Locales/GetAll', {}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
        .then(res => {
            response = res
        })
        .catch((error) => {
            response = error.response
        })
    return response

}
//** Calendario */
// --> Get Event Calendar
export const CargarEventosCalendario = async () => {

}
// --> Modificar Eventos
export const ModificarEventosCalendario = async () => {

}
//* Clientes */
export const GetClients = async () => {
    let response = []
    await axios.post(backend + '/api/auth0/Clientes/GetAll', {}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
        .then(res => {
            response = res
        })
        .catch((error) => {
            response = error.response
        })
    return response
}
// Get Clients
export const GetOnlyClient = async (data) => {
    let response = []
    await axios.post(backend + '/api/auth0/Clientes/GetClient', data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res => {
        response=res
    })
    .catch((error) => {
        response = error.response
    })
    return response
}
// Get Folio Junaeb
export const GetFolioJunaeb=async()=>{
    let response = []
    await axios.post(backend+'/api/auth0/Clientes/GetFoliosJunaeb', {}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res.data.Folio
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
// --> Push Document
export const InsertDocumentLC=async(data)=>{
    let response = []
    await axios.post(backend+'/api/auth0/Clientes/Lc/NewDocument',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
// --> Delete Document
export const RemoveDocumentLC=async(data)=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Clientes/LC/DeleteDocument',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
// --> Reajusta la fecha (Ordena)
export const ReajustarFecha=(fecha)=>{
    let trans = fecha.split('-')
    return trans[2]+'-'+trans[1]+'-'+trans[0]
}
//** Convenios */

// Get Convenios
export const getConvenios = async()=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Convenios/GetAll',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}

export const SearchExcel = async(data)=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Excel/Search',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}

 

//** Bodega */
// DB Productos
export const GetDBProductos=async()=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Productos/GetAll',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
// Bodega Armazones
export const GetBodegaArmazonesJSON=async()=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/GetAll',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const RebajarProductosBodegaArmazones=async(data)=>{
    ///
    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/Remove/Products',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const RebajarProductosVentasBodegaArmazones=async(data)=>{
    
    let response=[]
    await axios.post(backend+'/api/auth/Bodega/Armazones/Salida/Ventas',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const RebajarProductosMermasGarantiasBodegaArmazones=async(data)=>{
    
    let response=[]
    await axios.post(backend+'/api/auth/Bodega/Armazones/Salida/MermasGarantias',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const GetRegistroIngresoProveedor=async()=>{
    //
    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/RegistroIngreso/GetRegister',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const PushProductsBodega=async(data)=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/Add/NewProducts',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const PushProductsDB=async(data)=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Productos/PushNewItem',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const GenerarRegistroIngresoProveedor=async(data)=>{
    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/RegistroIngreso/Proveedor',data, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const GetRegistrosDeSalida=async()=>{
    ///
    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/RegistroSalida/GetRegister',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
export const GetRegistroSalidaVentas=async()=>{

    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/RegistroSalidaVentas/GetRegister',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
//
export const GetRegistroMermasGarantias=async()=>{

    let response=[]
    await axios.post(backend+'/api/auth0/Bodega/Armazones/RegistroMermasGarantias/GetRegister',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}
//Bodega Cristales
// Get JSON DATA
export const GetBodegaCristalesJSON = async()=>{
    let response =[]
    await axios.post(backend+'/api/auth0/Bodega/Cristales/GetAll',{}, {
        headers: { authorization: 'Bearere ' + Token }, 'content-type': 'multipart/form-data'
    })
    .then(res=>{
        response=res
    })
    .catch((error)=>{
        response=error.response
    })
    return response
}

import React,{useEffect,useState} from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import {Row,ButtonToolbar,InputGroup,Form,Button} from 'react-bootstrap'
import dayJS from 'dayjs'
import {GetRegistroMermasGarantias} from '../../../../function/functions'
const RegistroSalidaPage=()=>{
    const [RegistrosSalidas,setRegistrosSalidas]=useState([])
    const [DataShow,setDataShow]=useState([])
    const [InfoLoad, setInfoLoad] = useState(true)
    const Navigate = useNavigate();
    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            reorder: true,
            omit: true,
        },
        
        {
            name: 'Nombre Producto',
            selector: row => row.Producto,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Cantidad',
            selector: row => row.Cantidad,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Motivo',
            selector: row => row.Motivo,
            sortable: true,
            reorder: true,
        },{
            name: 'Sacado Por',
            sortable: true,
            reorder: true,
            selector: row =>row.SacadoPor,
        },{
            name: 'Fecha Salida',
            sortable: true,
            reorder: true,
            selector: row =>dayJS(row.CreateAt).format('DD/MM/YYYY HH:mm:ss'),
        },
        
    ];
    useEffect(()=>{
        LoadData()
    },[])
    async function LoadData(){
        let res = await GetRegistroMermasGarantias()
        if(res.status===200){
            setDataShow(res.data)
            setRegistrosSalidas(res.data)
        }
        if(res.status===400){
            setDataShow([])
            setRegistrosSalidas([])
        }
        
        setInfoLoad(false)
    }
    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(DataShow[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }
    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV() {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(DataShow);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    async function Filtrar(e) {
        if (e.length >= 1) {
            let regex = new RegExp(escapeRegex(e), 'gi');
            let res = RegistrosSalidas.filter((items) => items.OT.match(regex) || items.Producto.match(regex) )
            setDataShow(res)
        } else {
            setDataShow(RegistrosSalidas)
        }


    }
    const escapeRegex = (string: any) => {
        return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    async function Limpiar(){
        let inpFiltro = document.getElementById('Filtrador')
        inpFiltro.value=''
        setDataShow(RegistrosSalidas)
    }
     
    return(
        <>
        <Row className="rigth">
            <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                <InputGroup>
                    <Button onClick={() => downloadCSV()} variant="success">Exportar</Button>
                    
                    <Form.Control
                        type="text" onChange={(e) => Filtrar(e.target.value)}
                        placeholder="Filtrar ...."
                        id='Filtrador'
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                        className='w-full outline-none bg-transparent'
                    />
                    <Button onClick={()=>Limpiar()} variant="secondary">x</Button>
                </InputGroup>
            </ButtonToolbar>
            </Row>
        
      <br /> 
         <DataTable
                columns={columns}
                data={DataShow}
                progressPending={InfoLoad}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                dense
            />

        </>
    )
}

export default RegistroSalidaPage
import React from 'react'
import ListadoLocalesComponents from './Components/Locales'
import CalendarComponent from './Components/Calendar'
const Calendario = () => { 
    
    return (
        <div className='h-96 rounded-lg border-4 border-dashed border-gray-100 lg:h-full px-1'>
            <ListadoLocalesComponents />
            <CalendarComponent />
        </div>
    )
}

export default Calendario
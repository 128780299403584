import React from 'react'

const Dashboard = () =>{
    return(
        <div>
            Dashboard
        </div>
    )
}

export default Dashboard

import './App.css';

import MainRouter from './Router/MainRouter'
function App() {
  return (
    
      <div className="App ">
        <MainRouter />

      </div>


  );
}

export default App;

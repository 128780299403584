// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage,ref,uploadBytes,getDownloadURL,getBytes} from 'firebase/storage'
import {getFirestore,collection,addDoc,getDocs,doc,getDoc,query,where,setDoc,deleteDoc} from 'firebase/firestore'

import {GoogleAuthProvider,signInWithPopup,getAuth} from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const db=getFirestore(app)
export const storage = getStorage(app)


async function signInWithGoogle(gogoleProvider){
    try{
        const res = await signInWithPopup(auth,gogoleProvider)
        console.log('resultado =>'+res)
        console.log(res.user.uid)
        let resultado = await ExistGoogleAccountAccess(res.user.uid)
    }catch(error){
        console.log(error)
    }
}
async function ExistGoogleAccountAccess(uid){
    let result = null
    const docRef = doc(db,'users',uid)
        
        const res = await getDoc(docRef)
        console.log(res)
        result=res.exists()
    
    return result
}

export const GoogleAccess = async () =>{
    try{
        const googleProvider = new GoogleAuthProvider()
        let res = await signInWithGoogle(googleProvider)
        
    }catch(error){
        console.log('Error =>'+error)
    }
}


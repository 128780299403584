import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'

const sortOptions = [
  { name: 'Most Popular', href: '#', current: true },
  { name: 'Best Rating', href: '#', current: false },
  { name: 'Newest', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
]
const subCategories = [
  { name: 'Totes', href: '#' },
  { name: 'Backpacks', href: '#' },
  { name: 'Travel Bags', href: '#' },
  { name: 'Hip Bags', href: '#' },
  { name: 'Laptop Sleeves', href: '#' },
]
const filters = [
  {
    id: 'color',
    name: 'Color',
    options: [
      { value: 'white', label: 'White', checked: false },
      { value: 'beige', label: 'Beige', checked: false },
      { value: 'blue', label: 'Blue', checked: true },
      { value: 'brown', label: 'Brown', checked: false },
      { value: 'green', label: 'Green', checked: false },
      { value: 'purple', label: 'Purple', checked: false },
    ],
  },
  {
    id: 'category',
    name: 'Category',
    options: [
      { value: 'new-arrivals', label: 'New Arrivals', checked: false },
      { value: 'sale', label: 'Sale', checked: false },
      { value: 'travel', label: 'Travel', checked: true },
      { value: 'organization', label: 'Organization', checked: false },
      { value: 'accessories', label: 'Accessories', checked: false },
    ],
  },
  {
    id: 'size',
    name: 'Size',
    options: [
      { value: '2l', label: '2L', checked: false },
      { value: '6l', label: '6L', checked: false },
      { value: '12l', label: '12L', checked: false },
      { value: '18l', label: '18L', checked: false },
      { value: '20l', label: '20L', checked: false },
      { value: '40l', label: '40L', checked: true },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const SidebarComponent = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  const navigation = [
    { name: 'Inventario', href: '/Admin/Bodega/Inventario', current: true },
    { name: 'Ingreso Mercaderia', href: '/Admin/Bodega/Inventario/Ingreso', current: false },
    { name: 'Salida Mercaderia', href: '/Admin/Bodega/Inventario/Salida', current: false },
    { name: 'Salida Mercaderia Por Venta', href: '/Admin/Bodega/Inventario/SalidaPorVenta', current: false },
    { name: 'Mermas Y Garantias', href: '/Admin/Bodega/Inventario/MermasGarantias', current: false },
    { name: 'Registro De Ingresos', href: '/Admin/Bodega/Inventario/Registro/Ingreso', current: false },
    { name: 'Registro De Salidas', href: '/Admin/Bodega/Inventario/Registro/Salidas', current: false },
    { name: 'Registro De Salidas Ventas', href: '/Admin/Bodega/Inventario/Registro/SalidasPorVentas', current: false },
    { name: 'Registro Mermas y Garantias', href: '/Admin/Bodega/Inventario/Registro/MermasGarantias', current: false },
  ]
  const navigation2 = [
    {
      name: 'Dashboard',
      href: '/Admin/Dashboard',
      current: true
    },
    {
      name: 'Inventario',
      href: '/Admin/Dashboard',
    },
  ]



  return (
    <>
      <form className='hidden lg:block'>
        <div className="      ">
          <ul role="list" className=" space-y-4 min-h-screen border-r border-gray-400  text-sm font-medium text-gray-900 ">
            {navigation.map((items, index) =>
              <Fragment key={index}>
                <Link className=" flex no-underline items-start rounded-lg p-1 hover:bg-gray-100" to={items.href}>
                  <button type="button">
                  {items.name}
                  </button>
                </Link>
              </Fragment>
            )}
          </ul>
        </div>
      </form>
    </>

  )
}

export default SidebarComponent


import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import {Table,Row,InputGroup,Button,ButtonToolbar,Form} from 'react-bootstrap'
import dayJS from 'dayjs'
import { GetRegistroIngresoProveedor } from '../../../../function/functions'
const RegistroIngresoPage = () => {
    const [ListadoIngresos, setListadoIngreso] = useState([])
    const [DataFilter, setDataFilter] = useState([])
    const [InfoLoad, setInfoLoad] = useState(true)
    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            reorder: true,
            omit: true,
        },
        {
            name: 'Proveedor',
            selector: row => row.Proveedor,
            sortable: true,
            reorder: true,
        },
        {
            name: 'N° Factura',
            selector: row => row.NFactura,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Fecha Emision',
            selector: row => row.FechaEmision,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Fecha Recepcion',
            selector: row => row.FechaRecepcion,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Ingresado Por',
            selector: row => row.IngresadoPor,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Fecha Ingresado',
            selector: row => dayJS(row.CreateAt).format('DD/MM/YYYY HH:mm:ss'),
            sortable: true,
            reorder: true,
        },

    ];
    
    const ExpandedComponent = ({ data }) => <pre><Table><thead><tr><td>Producto</td><td>Cantidad</td></tr></thead><tbody>{data.Producto.map((item,index)=>
        <tr key={index}>
            <td>{item.Producto}</td>
            <td>{item.Cantidad}</td>
        </tr>
        )}
        </tbody>
        </Table>
    </pre>;

    


    useEffect(() => {
        LoadData()
    }, [])
    async function LoadData() {
        let res = await GetRegistroIngresoProveedor()
        if(res.status===200){
            setListadoIngreso(res.data)
            setDataFilter(res.data)
        }
        if(res.status===400){
            setListadoIngreso([])
            setDataFilter([])
        }
        
        setTimeout(() => {
            setInfoLoad(false)
        }, 2000);
        console.log(res)
    }
    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(DataFilter[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }
    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV() {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(DataFilter);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    async function Filtrar(e) {
        if (e.length >= 1) {
            let regex = new RegExp(escapeRegex(e), 'gi');
            let res = ListadoIngresos.filter((items) => items.Proveedor.match(regex) || items.NFactura.match(regex) )
            setDataFilter(res)
        } else {
            setDataFilter(ListadoIngresos)
        }


    }
    const escapeRegex = (string) => {
        return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    async function Limpiar(){
        let inpFiltro = document.getElementById('Filtrador')
        inpFiltro.value=''
        setDataFilter(ListadoIngresos)
    }
    return (
        <>
        <Row className="rigth">
            <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                <InputGroup>
                    <Button onClick={() => downloadCSV()} variant="success">Exportar</Button>
                    
                    <Form.Control
                        type="text" onChange={(e) => Filtrar(e.target.value)}
                        placeholder="Filtrar ...."
                        id='Filtrador'
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                        className='w-full outline-none bg-transparent'
                    />
                    <Button onClick={()=>Limpiar()} variant="secondary">x</Button>
                </InputGroup>
            </ButtonToolbar>
            </Row>
        <br/>
            <DataTable
                columns={columns}
                data={DataFilter}
                progressPending={InfoLoad}
                pagination
                expandOnRowClicked={true}
                expandableRows
                fixedHeader={true}
                expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={paginationComponentOptions}
            dense
            />
        </>
    )
}

const TablaItems  =()=>{
    return(
        <>
            <Table>
                <thead>
                    <tr>
                        <td>Producto</td>
                        <td>Cantidad</td>
                    </tr>
                </thead>
            </Table>
        </>
    )
}

export default RegistroIngresoPage
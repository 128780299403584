import React, { useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { Login } from '../../function/functions'
import { useNavigate } from 'react-router-dom'
import Modal from '../component/ModalErrorLogin'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Process from '../component/Proccess'
import { useSelector, useDispatch } from 'react-redux'
import { Loggin } from '../../features/Account/AccountSlice'
const LoginPage = () => {
    const [Username, setUsername] = useState('')
    const [Password, setPassword] = useState('')
    const [ShowModalError, setShowModalError] = useState(false)
    const [ShowProgress, setShowProgress] = useState(false)
    const Navigate = useNavigate();
    const [userLogin, setUserLogin] = useState(null)
    const dispatch = useDispatch();
    
    const LoginStatus = useSelector(state => state.Account)
    const CerrarModal = () => {
        setShowModalError(false)
    }



    const Send = async (e) => {
        e.preventDefault()
        setShowProgress(true)
        let formData = new FormData();    //formdata object
        formData.append('Username', Username);   //append the values with key, value pair
        formData.append('Password', Password);
        let res = await Login(formData)
        setTimeout(() => {
            setShowProgress(false)
            res.status === 400 && ErrorLogin()
            res.status === 200 && SuccessLogin(res)
        }, 3000);



    }

    const RedirectDashboard = (res) => {
        
        dispatch(Loggin({
            userToken : res.data.Jwt,
            userInfo : res.data.Datos,
            Role : res.data.Datos.Rol,

        }))
        localStorage.setItem('Token', res.data.Jwt)
        localStorage.setItem('User', JSON.stringify(res.data.Datos))
        Navigate("/Admin/Dashboard");
    }
    const ErrorLogin = () => {
        toast.error('Error En Datos De ingreso', {
            position: "top-left",
            autoClose: 1000,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            theme: "colored"
        });
    }
    const SuccessLogin = (res) => {
        
        toast.success('Ingresando ...', {
            closeOnClick: false,
            autoClose: 1000,
            pauseOnFocusLoss: false,
            onClose: () => {
                RedirectDashboard(res)
            },
            theme: "colored"
        });
    }



    return (
        <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <ToastContainer />
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto h-12 w-auto"
                            src="/assets/Icon.svg"
                            alt="Your Company"
                        />
                        
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">

                        </h2>

                    </div>
                    <form className="mt-8 space-y-6" onSubmit={Send} method="POST" encType="multipart/form-data">
                    
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="Username" className="sr-only">
                                    USername
                                </label>
                                <input
                                    onChange={(e) => setUsername(e.target.value)}
                                    id="Username"
                                    name="Username"
                                    type="text"
                                    autoComplete="userName"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Username"
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Password"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Recordar?
                                </label>
                            </div>

                            <div className="text-sm">
                                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    ¿Olvidaste tu Contraseña?
                                </a>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                                </span>
                                Ingresar
                            </button>
                        </div>
                    </form>
                </div>

            </div>
            <Process isOpen={ShowProgress} />
            <Modal isOpen={ShowModalError} CloseModal={CerrarModal} />
        </>
    )
}

export default LoginPage
import { configureStore } from "@reduxjs/toolkit";

import AccoutReducer from "../features/Account/AccountSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key : 'root',
  storage,
  whitelist : ['AccountState']
}

const rootReducer = combineReducers({
  AccountState : AccoutReducer
})
const persistedReducer = persistReducer(persistConfig,rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});